import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppMaterialModule } from 'src/app/app-material.module';
import { IMessage } from '../interfaces/shared.interfaces';

@Component( {
    selector: 'app-msg-modal',
    templateUrl: 'messageModal.component.html',
    styleUrls: ['messageModal.component.scss'],
    standalone: true,
    imports: [CommonModule, AppMaterialModule]
})

export class MessageModalComponent
{
    constructor(@Inject(MAT_DIALOG_DATA) public data: IMessage, public dialogRef: MatDialogRef<MessageModalComponent>) {
     }

     ok()
     {
         this.dialogRef.close('ok');
     }

     cancel()
     {
        this.dialogRef.close('cancel');
     }
}
