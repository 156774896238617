import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class WebApiService
{
    public static serverUrl = environment.serverUrl; //'http://localhost:14134/';
    // public static serverUrl = 'http://192.168.32.151/qdoc';

    constructor()
    {
    }
}
