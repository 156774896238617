<div class="p-10 flex-column justify-start items-stretch">
    <h2 mat-dialog-title>{{data.title}}</h2>

    <div mat-dialog-content >
        <form [formGroup]="loginForm">
            <p>{{data.message}}</p>
            <div class="full-width">
                <mat-form-field  appearance="outline" class="dense-3 full-width" subscriptSizing="dynamic">
                    <mat-label>Enter the value requested</mat-label>
                    <input matInput
                        type="text"
                        placeholder="Enter the value"
                        formControlName="input">
                </mat-form-field>
            </div>
        </form>
    </div>
    <div mat-dialog-actions class="mt-5 mb-3 flex-row gap-10">
        <button mat-raised-button
            color="primary"
            (click)="save()"
            cdkFocusInitial>Ok
        </button>
        <button mat-raised-button
            (click)="cancel()">Cancel
        </button>
    </div>
</div>