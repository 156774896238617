import { RouterModule, Routes } from '@angular/router';

import { AppGuard } from './AuthModule/app.guard';
import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { PageNotFoundComponent } from './PageNotFound/page-not-found.component';
import { VerifyInvitationComponent } from './AuthModule/Components/Verify-invitation/verify-invitation.component';
import { RecoveryComponent } from './AuthModule/Components/PasswordRecovery/recovery.component';
import { UnitsComponent } from './MasterDataModule/Components/MyUnits/my-units.component';

const routes: Routes = [
    {
        path: 'master',
        canActivate: [AppGuard],
        loadChildren: () =>
        {
            return import("./MasterDataModule/master.routes").then(m => m.MASTER_ROUTES);
        }
    },
    {
        path: 'auth',
        loadChildren: () => import('./AuthModule/auth.routes').then(m => m.AUTH_ROUTES)
    },
    { component: HomeComponent, path: 'home', canActivate: [AppGuard] },
    { component: UnitsComponent, path: 'units', canActivate: [AppGuard] },
    { component: VerifyInvitationComponent, path: 'invitation/:pin' },
    { component: RecoveryComponent, path: 'recover' },
    { component: PageNotFoundComponent, path: 'notfound' },
    { component: PageNotFoundComponent, path: '**' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule
{
}
