import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({providedIn:'root'})
export class InformationService
{
    constructor(private snackBar: MatSnackBar)
    {
    }

    ShowSnackError(message: string)
    {
        this.snackBar.open(message, '', {duration: 5000, panelClass: 'snack-warning', horizontalPosition:'right'});
    }

    ShowSnackWarning(message: string)
    {
        this.snackBar.open(message, '', {duration: 5000, panelClass: 'snack-warning', horizontalPosition:'right'});
    }

    ShowSnackInfo(message: string)
    {
        this.snackBar.open(message,'', {duration: 2000, panelClass: 'snack-info', horizontalPosition:'right'});
    }
}
