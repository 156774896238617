import { BehaviorSubject, Observable, Subject, filter, takeUntil } from "rxjs";
import { Injectable, OnDestroy } from "@angular/core";

import { AuthStore } from "../../AuthModule/Auth.store";
import { IUser } from '../../AuthModule/Interfaces/authentication.interfaces';
import { IUserPreference } from '../Interfaces/IUserPreference';
import { PreferenceService } from "../Services/userPreference.service";
import { isNonNull } from "../../SharedModule/functions/nonNull.function";

@Injectable({
    providedIn: 'root'
})
export class PreferenceStore implements OnDestroy
{
    private destroyed$ = new Subject<void>();

    private _preference: IUserPreference;
    private _preference$ = new BehaviorSubject<IUserPreference | null>(null);
    public readonly preference$: Observable<IUserPreference> = this._preference$.asObservable().pipe(filter(isNonNull));

    constructor(private service: PreferenceService, auth: AuthStore)
    {
        auth.user$.pipe(takeUntil(this.destroyed$)).subscribe(user => { this.getPreference(user); });
    }

    private getPreference(user: IUser)
    {
        this.service.getUserPreference(user).pipe(takeUntil(this.destroyed$))
            .subscribe(resp =>
            {
                if (resp.success)
                {
                    this._preference = resp.userPreference;
                    this._preference$.next(this._preference);
                }
            });
    }

    public setLastnavigation(path: string)
    {
        this._preference.lastNavigation = path;
        this.updatePreference(this._preference);
    }

    public setFacilityPageSize(size: number)
    {
        this._preference.facilityPageSize = size;
        this.updatePreference(this._preference);
    }

    public setGroupPageSize(size: number)
    {
        this._preference.groupPageSize = size;
        this.updatePreference(this._preference);
    }

    public setLocationPageSize(size: number)
    {
        this._preference.locationPageSize = size;
        this.updatePreference(this._preference);
    }

    public setDocumentsPageSize(size: number)
    {
        this._preference.documentsPageSize = size;
        this.updatePreference(this._preference);
    }

    public setKeepMacroOpen(value: boolean)
    {
        this._preference.keepMacroOpen = value;
        this.updatePreference(this._preference);
    }


    public setDocumentTypes(docTypes: string[])
    {
        let concatenated = '';
        docTypes.forEach(t =>
        {
            concatenated += `${t}|`;
        });
        concatenated = concatenated.slice(0, concatenated.length -1);
        this._preference.captureDocumentTypes = concatenated;
        this.updatePreference(this._preference);
    }

    public setLocations(locations: string[])
    {
        let concatenated = '';
        locations.forEach(l =>
        {
            concatenated += `${l}|`;
        });
        concatenated = concatenated.slice(0, concatenated.length -1);
        this._preference.captureLocations = concatenated;
        this.updatePreference(this._preference);
    }

    public setComputers(computers: string[])
    {
        let concatenated = '';
        computers.forEach(l =>
        {
            concatenated += `${l}|`;
        });
        concatenated = concatenated.slice(0, concatenated.length -1);
        this._preference.captureComputers = concatenated;
        this.updatePreference(this._preference);
    }

    public setUsers(users: string[])
    {
        let concatenated = '';
        users.forEach(l =>
        {
            concatenated += `${l}|`;
        });
        concatenated = concatenated.slice(0, concatenated.length -1);
        this._preference.captureUsers = concatenated;
        this.updatePreference(this._preference);
    }

    public setPolicyPageSize(size: number)
    {
        this._preference.policyPageSize = size;
        this.updatePreference(this._preference);
    }

    updatePreference(preference: IUserPreference)
    {
        this.service.updateUserPreference(preference).pipe(takeUntil(this.destroyed$))
            .subscribe(resp =>
            {
                this._preference = resp.userPreference;
                this._preference$.next(this._preference);
            });
    }

    ngOnDestroy(): void
    {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

}