<div [ngStyle]="{'height':'calc(100vh - ' + captionHeight + 'px)'}" class="flex-row gap-5 items-stretch">
    <div #leftside class="left-side flex-row justify-between items-stretch"
        [@openClosed]="{value: isOpen ? 'open' : 'closed', params: {leftWidth: width}}">
        <ng-content></ng-content>
    </div>
    <div class="right-side flex-100">
        <router-outlet>
        </router-outlet>
    </div>
</div>

<!-- [@openClosed]="{value: isOpen ? 'open' : 'closed', params: {leftWidth: width}}" -->
<!-- [ngStyle]="{'height':'calc(100% - ' + (captionHeight+2) + 'px)', 'top': (captionHeight+1) + 'px'}" -->