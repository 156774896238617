import { PreferenceStore } from './../../../MasterDataModule/Stores/userPreference.store';
import { Component, Inject, OnDestroy } from '@angular/core';
import { take, takeUntil, tap } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';

import { AppMaterialModule } from '../../../app-material.module';
import { AuthStore } from '../../Auth.store';
import { DialogService } from '../../../SharedServices/dialog.service';
import { IUser } from '../../Interfaces/authentication.interfaces';
import { AuthorizationService } from '../../Authorization.service';

@Component({
    selector: 'app-user-profile',
    templateUrl: 'profile.component.html',
    styleUrls: ['profile.component.scss'],
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, FormsModule, AppMaterialModule]
})
export class UserProfileComponent implements OnDestroy
{
    private destroyed$ = new Subject<void>();

    user: IUser;

    canSetLocation: Observable<boolean>; 

    profileForm = new FormGroup(
        {
            firstName: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
            lastName: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
            mobileNumber: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]),
            email: new FormControl('', [Validators.required, Validators.email])
        }
    );

    get firstName() { return this.profileForm.get('firstName')!; }
    get lastName() { return this.profileForm.get('lastName')!; }
    get mobileNumber() { return this.profileForm.get('mobileNumber')!; }
    get email() { return this.profileForm.get('email')!; }

    constructor( 
        @Inject(MAT_DIALOG_DATA) public data: IUser,
        public dialogRef: MatDialogRef<UserProfileComponent>,
        private userStore: AuthStore, 
        public authorization: AuthorizationService,
        preperence: PreferenceStore,
        private dialogs: DialogService
        )
    { 
        this.user = data;
        if (this.user)
        {
            this.firstName?.setValue(this.user.firstName ?? '');
            this.lastName?.setValue(this.user.lastName ?? '');
            this.mobileNumber?.setValue(this.user.mobileNumber ?? '');
            this.email?.setValue(this.user.email ?? '');
        }
    }

    cancel()
    {
        this.dialogRef.close();
    }

    save()
    {
        this.user.firstName = this.firstName.value ?? '';
        this.user.lastName = this.lastName?.value ?? '';
        this.user.mobileNumber = this.mobileNumber?.value ?? '';
        this.user.email = this.email?.value ?? '';

        this.userStore.updateUser(this.user)
            .pipe(
                takeUntil(this.destroyed$),
                tap(res =>
                {
                    if (res.success)
                    {
                        this.dialogs.waitMessage('Save', 'Changes have been saved', 0.4)
                        .pipe(take(1))
                        .subscribe(resp => this.dialogRef.close());
                    }
                    else
                    {
                        this.dialogs.showMessage('Save Failed', 'Changes were not saved! ' + res.messages);
                    }
                })
            )
            .subscribe();
    }

    ngOnDestroy()
    {
        this.destroyed$.next();
        this.destroyed$.complete();
    }
}