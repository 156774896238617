import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';

import { AppMaterialModule } from '../../../app-material.module';
import { DialogService } from '../../../SharedServices/dialog.service';
import { IUnit } from '../../Interfaces/IUnit';
import { DateTime } from 'luxon';
import { NgxCurrencyDirective, NgxCurrencyInputMode } from 'ngx-currency';

@Component({
    selector: 'app-unit',
    templateUrl: 'unit.component.html',
    styleUrls: ['unit.component.scss'],
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, FormsModule, AppMaterialModule, NgxCurrencyDirective]
})
export class UnitComponent implements OnInit, OnDestroy
{
    @ViewChild('dp') input: ElementRef;
    
    private destroyed$ = new Subject<void>();

    unit: IUnit;
    mode = NgxCurrencyInputMode.Natural;
    onSaleForMonths = 0;
    soldMonths = 0;
    amountDue = 0;

    unitForm = new FormGroup(
        {
            // village: new FormControl('',  [Validators.required, Validators.minLength(3), Validators.maxLength(100)]),
            village: new FormControl('', {nonNullable: true, validators: [Validators.required, Validators.minLength(3), Validators.maxLength(100)]}),
            unitNumber: new FormControl(),
            datePurchased: new FormControl(''),
            forSale: new FormControl(false, {nonNullable: true}),
            dateForSale: new FormControl(''),
            sold: new FormControl(false, {nonNullable: true}),
            dateSold: new FormControl(''),
            paidOut: new FormControl(false, {nonNullable: true}),
            amountSold: new FormControl(0),
            amountPaid: new FormControl(0),
            litigation: new FormControl(false, {nonNullable: true}),
            legalRepresentative: new FormControl(''),
            litigationWon: new FormControl(false, {nonNullable: true}),
            litigationLost: new FormControl(false, {nonNullable: true}),
            litigationCost: new FormControl(0),
            payingLevies: new FormControl(false, {nonNullable: true}),
            amountLevies: new FormControl(0),
        }
    );

    get village() { return this.unitForm.get('village')!; }
    get unitNumber() { return this.unitForm.get('unitNumber')!; }
    get datePurchased() { return this.unitForm.get('datePurchased')!; }
    get forSale() { return this.unitForm.get('forSale')!; }
    get dateForSale() { return this.unitForm.get('dateForSale')!; }
    get sold() { return this.unitForm.get('sold')!; }
    get dateSold() { return this.unitForm.get('dateSold')!; }
    get paidOut() { return this.unitForm.get('paidOut')!; }
    get amountSold() { return this.unitForm.get('amountSold')!; }
    get amountPaid() { return this.unitForm.get('amountPaid')!; }
    get litigation() { return this.unitForm.get('litigation')!; }
    get legalRepresentative() { return this.unitForm.get('legalRepresentative')!; }
    get litigationWon() { return this.unitForm.get('litigationWon')!; }
    get litigationLost() { return this.unitForm.get('litigationLost')!; }
    get litigationCost() { return this.unitForm.get('litigationCost')!; }
    get payingLevies() { return this.unitForm.get('payingLevies')!; }
    get amountLevies() { return this.unitForm.get('amountLevies')!; }

    constructor(@Inject(MAT_DIALOG_DATA) public data: IUnit, public dialogRef: MatDialogRef<UnitComponent>, private dialogs: DialogService)
    { 
        this.unit = JSON.parse(JSON.stringify(data));
        
        this.village.setValue(this.unit.villageName);
        this.unitNumber.setValue(this.unit.unitNumber);
        if (this.unit.datePurchased) {
            let dt = DateTime.fromISO(this.unit.datePurchased);
            this.datePurchased.setValue(dt.toFormat('yyyy-LL-dd'));  
        } 
        
        this.forSale.setValue(this.unit.forSale);
        if (this.unit.dateForSale) {
            let dt = DateTime.fromISO(this.unit.dateForSale);
            this.dateForSale.setValue(dt.toFormat('yyyy-LL-dd'));  
            const diff = dt.diffNow(['months']);
            const months = Math.abs(Math.round(diff.months));
            this.onSaleForMonths = months;
        } 

        this.sold.setValue(this.unit.sold);
        
        if (this.unit.dateSold) {
            let dt = DateTime.fromISO(this.unit.dateSold);
            this.dateSold.setValue(dt.toFormat('yyyy-LL-dd'));  
            const diff = dt.diffNow(['months']);
            const months = Math.abs(Math.round(diff.months));
            this.soldMonths = months;

        } 
        this.paidOut.setValue(this.unit.paidOut);

        if (this.unit.amountSold) {
            this.amountSold.setValue(this.unit.amountSold);
        }

        if (this.unit.amountPaid) {
            this.amountPaid.setValue(this.unit.amountPaid);
        }

        if (this.unit.amountSold !== undefined && this.unit.amountPaid !== undefined) {
            this.amountDue = this.unit.amountSold - this.unit.amountPaid;
        }

        this.litigation.setValue(this.unit.litigation);

        if (this.unit.legalRepresentative) {
            this.legalRepresentative.setValue(this.unit.legalRepresentative);
        }

        if (this.unit.litigationWon) {
            this.litigationWon.setValue(this.unit.litigationWon);
        }
        if (this.unit.litigationLost) {
            this.litigationLost.setValue(this.unit.litigationLost);
        }
        if (this.unit.litigationCost) {
            this.litigationCost.setValue(this.unit.litigationCost);
        }
        
        this.payingLevies.setValue(this.unit.payingLevies);

        if (this.unit.amountLevies) {
            this.amountLevies.setValue(this.unit.amountLevies);
        }

    }

    cancel()
    {
        this.dialogRef.close();
    }

    save()
    {
        this.unit.villageName = this.village.value;
        this.unit.unitNumber = this.unitNumber.value;
        if (this.datePurchased.value) {
            this.unit.datePurchased = this.datePurchased.value;
        }

        this.unit.forSale = this.forSale.value;
        if (this.dateForSale.value) {
            this.unit.dateForSale = this.dateForSale.value;
        }

        this.unit.sold = this.sold.value;

        if (this.dateSold.value) {
            this.unit.dateSold = this.dateSold.value;
        }
        this.unit.paidOut = this.paidOut.value;
        if (this.amountSold.value) {
            this.unit.amountSold = this.amountSold.value;
        }
        if (this.amountPaid.value !== null) {
            this.unit.amountPaid = this.amountPaid.value;
        }
        this.unit.litigation = this.litigation.value;

        if (this.legalRepresentative.value) {
            this.unit.legalRepresentative = this.legalRepresentative.value;
        }
        if (this.litigationWon.value) {
            this.unit.litigationWon = this.litigationWon.value;
        }
        if (this.litigationLost.value) {
            this.unit.litigationLost = this.litigationLost.value;
        }
        if (this.litigationCost.value) {
            this.unit.litigationCost = this.litigationCost.value;
        }
        this.unit.payingLevies = this.payingLevies.value;
        if (this.amountLevies.value) {
            this.unit.amountLevies = this.amountLevies.value;
        }

        this.dialogRef.close(this.unit);
    }


    openCalendar(el: any)
    {
        el.showPicker();
    }


    ngOnInit()
    {
    }

    ngOnDestroy()
    {
        this.destroyed$.next();
        this.destroyed$.complete();
    }
}