import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';

import { AuthStore } from './AuthModule/Auth.store';
import { AuthorizationService } from './AuthModule/Authorization.service';
import { DialogService } from './SharedServices/dialog.service';
import { IUser } from './AuthModule/Interfaces/authentication.interfaces';
import { LoadingService } from './SharedServices/loading.service';
import { MatSidenav } from '@angular/material/sidenav';
import { PreferenceStore } from './MasterDataModule/Stores/userPreference.store';
import { CaptionService } from './SharedServices/title.service';
import { UserProfileComponent } from './AuthModule/Components/Profile/profile.component';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy, AfterViewInit
{
    // name = "AppComponent";
    // title = 'Q-Doc';
    private destroyed$ = new Subject<void>();
    user: IUser | null = null;
    userName = '';
    loading = false;
    operation: string = '';
    caption: string = '';
    captionHeight: number = 50;

    isOpen = false;

    canSeeHome!: Observable<boolean>;
    canSeeUnits!: Observable<boolean>;
    canSeeMaster!: Observable<boolean>;
    canSeeRoles!: Observable<boolean>;
    canSeeLocations!: Observable<boolean>;
    canSeeUsers!: Observable<boolean>;
    canSeePolicies!: Observable<boolean>;
    canSeeDocumentTypes!: Observable<boolean>;

    @ViewChild('menu') sideNav = {} as MatSidenav;
    @ViewChild('screen') screenEl = {} as ElementRef;
    @ViewChild('caption') captionEl = {} as ElementRef;

    constructor(
        changeDetector: ChangeDetectorRef,
        private auth: AuthStore,
        private preference: PreferenceStore,
        private dialogs: DialogService,
        private title: Title,
        public authorization: AuthorizationService,
        public loadingService: LoadingService,
        public captionService: CaptionService
        )
    {
        // console.log('wasm path: ' + DEF_WASM_PATH);
        this.loadingService.loading$
            .pipe
            (
                takeUntil(this.destroyed$),
                tap(l =>
                {
                    setTimeout(() =>
                    {
                        this.operation = l;
                        changeDetector.detectChanges();
                    }, 1);
                })
            )
            .subscribe();

        this.captionService.title$
            .pipe(
                takeUntil(this.destroyed$),
                tap(cap =>
                {
                    if (cap)
                    {
                        this.caption = cap;
                    }
                })
            )
            .subscribe();

        this.auth.user$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(user =>
            {
                console.log('Got user', user);
                this.user = user;
                this.canSeeHome = this.authorization.canI('see', 'home-menu');
                this.canSeeUnits = this.authorization.canI('see', 'units-menu');
                this.canSeeMaster = this.authorization.canI('see', 'master-data-menu');
                this.canSeeRoles = this.authorization.canI('see', 'roles-menu');
                this.canSeeLocations = this.authorization.canI('see', 'locations-menu');
                this.canSeeUsers = this.authorization.canI('see', 'users-menu');
                this.canSeePolicies = this.authorization.canI('see', 'policies-menu');
                this.canSeeDocumentTypes = this.authorization.canI('see', 'document-types-menu');

                this.userName = user.userName;

                title.setTitle(`Action [${user.firstName??user.userName}]`);

                setTimeout(() =>
                {
                    this.isOpen = true;
                }, 1000);
            });

            // title.setTitle('Q-Doc');
    }

    showProfile(event: MouseEvent)
    {
        event.stopPropagation();
        if (this.user)
        {
            this.dialogs.ShowComponent(UserProfileComponent, this.user, .5)
                .pipe(take(1))
                .subscribe();
        }

        return false;
    }

    saveNavigation(nav: string)
    {
        this.preference.setLastnavigation(nav);
    }

    home(event: MouseEvent)
    {
        event.stopPropagation();
        this.isOpen = !this.isOpen;

        // if (this.sideNav.opened)
        // {
        //     this.sideNav.close();
        //     this.captionService.setExpanded(false);
        //     console.log('closed')
        //     return false;
        // }

        // this.sideNav.open();
        this.captionService.setExpanded(true);
        return false;
    }


    logoff(event: MouseEvent)
    {
        event.stopPropagation();

        this.dialogs.ask('Log Off', 'Are you sure you want to log off?', 0.4)
            .pipe(
                take(1),
                tap(res =>
                {
                    if (res && res == 'ok')
                    {
                        this.auth.logout();
                        this.sideNav.close();
                    }
                })
            )
            .subscribe();

        return false;
    }

    ngAfterViewInit(): void
    {
        this.captionHeight = this.captionEl.nativeElement.clientHeight;
        this.captionService.setCaptionHeight(this.captionHeight, "App");
        // setTimeout(() =>
        // {
        //     this.sideNav.open();
        // }, 1000);

    }

    ngOnDestroy(): void
    {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

}
