import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WebApiService } from '../../SharedServices/webapi.service';
import { IBaseResponse } from '../../Interfaces/IBaseResponse.interface';
import { IRolesResponse } from '../Interfaces/IRolesResponse';
import { IRole } from '../Interfaces/IRole.interface';
import { IRoleRequest } from '../Interfaces/IRoleRequest.interface';
import { IRoleResponse } from '../Interfaces/IRoleResponse';
import { LoadingService } from "../../SharedServices/loading.service";
import { IUpdateUserRolesRequest } from '../Interfaces/IUpdateUserRolesRequest';

@Injectable({
    providedIn: 'root'
})
export class RoleService {

    private rolesUrl = 'Role';

    constructor(private http: HttpClient, private loading: LoadingService) { }

    public getRoles()
    {
        const obs$ =   this.http.get<IRolesResponse>(WebApiService.serverUrl + this.rolesUrl + '/GetRoles');
        return this.loading.showUntilCompleted(obs$, 'Getting roles...');
    }

    public getUserRoles(userId: number)
    {
        const obs$ = this.http.get<IRolesResponse>(WebApiService.serverUrl + this.rolesUrl + '/GetUserRoles?userId=' + userId);
        return this.loading.showUntilCompleted(obs$, 'Getting roles...');
    }

    addRole(role: IRoleRequest)
    {
        const obs$ = this.http.post<IRoleResponse>(WebApiService.serverUrl + this.rolesUrl + '/AddRole', {Role: role});
        return this.loading.showUntilCompleted(obs$, 'Adding role...');
    }

    updateRole(role: IRole)
    {
        const obs$ = this.http.post<IRoleResponse>(WebApiService.serverUrl + this.rolesUrl + '/UpdateRole', {Role: role});
        return this.loading.showUntilCompleted(obs$, 'Updating role...');
    }

    updateUserRoles(request: IUpdateUserRolesRequest)
    {
        const obs$ = this.http.post<IBaseResponse>(WebApiService.serverUrl + this.rolesUrl + '/UpdateUserRoles', request);
        return this.loading.showUntilCompleted(obs$, 'Updating roles...');
    }

    deleteRole(role: IRole)
    {
        const obs$ = this.http.post<IBaseResponse>(WebApiService.serverUrl + this.rolesUrl + '/DeleteRole', {Role: role});
        return this.loading.showUntilCompleted(obs$, 'Deleting role...');
    }
}