import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LoadingService } from "../../SharedServices/loading.service";
import { IInformationResponse } from "../Interfaces/IInformationResponse";
import { WebApiService } from "../../SharedServices/webapi.service";

@Injectable({providedIn: 'root'})
export class InformationService {

    private infoUrl = 'Info';

    constructor(private http: HttpClient, private loading: LoadingService) { }

    public getInformation()
    {
        const obs$ =   this.http.get<IInformationResponse>(WebApiService.serverUrl + this.infoUrl + '/GetInfo');
        return this.loading.showUntilCompleted(obs$, 'Getting information...');
    }
}