import { AuthStore } from '../../AuthModule/Auth.store';
import { HttpClient } from "@angular/common/http";
import { IUser } from '../../AuthModule/Interfaces/authentication.interfaces';
import { IUserPreference } from '../Interfaces/IUserPreference';
import { IUserPreferenceResponse } from '../Interfaces/IUserPreferenceResponse';
import { Injectable } from "@angular/core";
import { WebApiService } from "../../SharedServices/webapi.service";

@Injectable({
    providedIn: 'root'
})
export class PreferenceService
{
    private preferenceUrl = 'UserPreference';

    constructor(private http: HttpClient, private auth: AuthStore)
    {
    }

    public getUserPreference(user: IUser)
    {
        return this.http.get<IUserPreferenceResponse>(WebApiService.serverUrl + this.preferenceUrl + '/GetUserPreference');
    }

    public updateUserPreference(preference: IUserPreference)
    {
        return this.http.put<IUserPreferenceResponse>(WebApiService.serverUrl + this.preferenceUrl + '/UpdateUserPreference', { userPreference: preference });
    }
}