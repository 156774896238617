import { UnitService } from './../Services/unit.service';
import { IUnit } from './../Interfaces/IUnit';
import { Injectable, OnDestroy } from "@angular/core";
import { Observable, ReplaySubject, Subject } from "rxjs";
import { map, takeUntil, tap } from 'rxjs/operators';

import { AuthStore } from '../../AuthModule/Auth.store';
import { IBaseResponse } from "../../Interfaces/IBaseResponse.interface";

@Injectable({
    providedIn: 'root'
})
export class UnitsStore implements OnDestroy
{
    private destroyed$ = new Subject<void>();

    private _units: IUnit[];
    private _units$ = new ReplaySubject<IUnit[]>(1);
    public readonly units$: Observable<IUnit[]> = this._units$.asObservable();

    constructor(private service: UnitService, auth: AuthStore)
    {
        auth.user$.pipe(takeUntil(this.destroyed$)).subscribe(user => {this.getUnits();});
    }

    getUnits()
    {
        this.service.getUnits()
            .pipe(
                takeUntil(this.destroyed$)
            )
            .subscribe(resp =>
            {
                if (resp.success)
                {
                    this._units = resp.units;
                    this._units$.next(this._units);
                }
            });
    }

    addUnit$(unit: IUnit)
    {
        return this.service.addUnit(unit)
            .pipe(
                takeUntil(this.destroyed$),
                tap(response =>
                {
                    if (response.success)
                    {
                        this._units.push(response.unit);
                        this._units$.next(this._units);
                    }
                }),
                map(resp =>
                {
                    return resp as IBaseResponse;
                })
            );
    }

    updateUnit$(unit: IUnit)
    {
        return this.service.updateUnit(unit)
            .pipe(
                takeUntil(this.destroyed$),
                tap(response =>
                {
                    if (response.success)
                    {
                        const existing = this._units.find(u => u.id == unit.id);
                        if (existing)
                        {
                            const index = this._units.indexOf(existing);
                            if (index >= 0)
                            {
                                this._units.splice(index, 1, response.unit);
                            }
                        }
                        else
                        {
                            this._units.push(response.unit);
                        }
                    }
                    this._units$.next(this._units);
                }),
                map(resp =>
                {
                    return resp as IBaseResponse;
                })
            );
    }

    deleteUnit(unit: IUnit)
    {
        return this.service.deleteUnit(unit)
            .pipe(
                takeUntil(this.destroyed$),
                tap(response =>
                {
                    if (response.success)
                    {
                        const existing = this._units.find(u => u.id == unit.id);
                        if (existing)
                        {
                            const index = this._units.indexOf(existing);
                            if (index >= 0)
                            {
                                this._units.splice(index, 1);
                            }
                        }

                        this._units$.next(this._units);
                    }
                }),
                map(resp =>
                {
                    return resp as IBaseResponse;
                })
            );
    }

    ngOnDestroy(): void
    {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

}