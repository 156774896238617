<div class="flex-row mt-5">
    <div class="flex-30"></div>
    <mat-card class="flex-40 mat-elevation-z8 p-5">

        <mat-card-title class="title">Recover Identity</mat-card-title>
        <mat-card-content>
            <div class="flex-row justify-start items-center gap-10" *ngIf="!keyRequired && !newPasswordRequired">
                <div>
                    <mat-checkbox [(ngModel)]="forgotName" (change)="checkChanged('name')" color="primary">
                        Forgot User Name
                    </mat-checkbox>
                </div>
                <div>
                    <mat-checkbox [(ngModel)]="forgotPassword" (change)="checkChanged('password')" color="primary">
                        Forgot Password
                    </mat-checkbox>
                </div>
            </div>

            <form [formGroup]="recoveryForm" class="mt-3">
                <div class="flex-column" *ngIf="!keyRequired && !newPasswordRequired">
                    <mat-form-field *ngIf="forgotPassword" appearance="outline" class="dense-3" subscriptSizing="dynamic">
                        <mat-label>Please enter your user Name</mat-label>
                        <input matInput
                            (keyup.enter)="reset($event)"
                            type="text"
                            placeholder="User name"
                            formControlName="userName">
                    </mat-form-field>

                    <mat-form-field *ngIf="forgotName" appearance="outline" class="dense-3" subscriptSizing="dynamic">
                        <mat-label>Please enter your email address</mat-label>
                        <input matInput
                            (keyup.enter)="reset($event)"
                            type="email"
                            placeholder="Registered email address"
                            formControlName="email">
                    </mat-form-field>
                </div>

                <div class="flex-column" *ngIf="keyRequired && !newPasswordRequired">
                    <mat-form-field appearance="outline" class="dense-3" subscriptSizing="dynamic">
                        <mat-label>Please enter the reset key sent in an email to you.</mat-label>
                        <input matInput
                            type="text"
                            (keyup.enter)="validateKey($event)"
                            placeholder="Reset key"
                            formControlName="key">
                    </mat-form-field>
                </div>

                <div class="flex-column" *ngIf="newPasswordRequired && !keyRequired">
                    <mat-form-field appearance="outline" class="dense-3" subscriptSizing="dynamic">
                        <mat-label>Please enter your new password.</mat-label>
                        <input matInput
                            type="password"
                            placeholder="New password"
                            formControlName="password">
                    </mat-form-field>
                </div>

            </form>

        </mat-card-content>
        <mat-card-actions>
            <div class="flex-column items-stretch">
                <div class="flex-row justify-start items-center gap-20 mt-5">
                    <button mat-raised-button
                        *ngIf="!keyRequired"
                        color="primary"
                        (click)="reset($event)">
                        Ok
                    </button>
                    <div class="text-center" *ngIf="!keyRequired">
                        <a class="d-block small"
                            href
                            (click)="toRegister($event)">Register</a>
                    </div>
                    <div class="text-center">
                        <a class="d-block small"
                            href
                            (click)="toLogin($event)">Login</a>
                    </div>
                </div>
                <!-- <div>
                    forgot password={{forgotPassword}}; forgot name={{forgotName}}; new password req={{newPasswordRequired}}
                </div> -->
            </div>
        </mat-card-actions>
    </mat-card>
    <div class="flex-30"></div>
</div>