<mat-card class="p-5">
    <mat-card-title class="title">Details for {{unit.villageName}} {{unit.unitNumber}}</mat-card-title>
    <mat-card-content>

        <form [formGroup]="unitForm"
            ng-submit
            class="mt-5 flex-column items-stretch gap-10">
            <div class="flex-row justify-between gap-5">
                <div class="flex-50">
                    <mat-form-field class="full-width dense-3">
                        <mat-label>Village Name</mat-label>
                        <input matInput
                            type="text"
                            placeholder="Enter the village name"
                            formControlName="village">
                        <mat-error *ngIf="village.hasError('required')">
                            Village is required
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="flex-25">
                    <mat-form-field class="dense-3">
                        <mat-label>Unit Number</mat-label>
                        <input matInput
                            type="number"
                            placeholder="Unit Number"
                            formControlName="unitNumber">
                        <mat-error *ngIf="unitNumber.hasError">{{unitNumber.errors}}</mat-error>
                    </mat-form-field>
                </div>
                <div class="flex-25">
                    <mat-form-field class="dense-3 flex-row justify-start items-center gap-5" subscriptSizing="dynamic">
                        <mat-label> Date Purchased</mat-label>
                        <div class="flex-row justify-between items-center">
                            <input matInput #dp class="flex-80"
                                type="date"
                                placeholder="Date Purchased"
                                formControlName="datePurchased">
                            <mat-icon class="clickable flex-10" (click)="openCalendar(dp)">calendar_month</mat-icon>

                        </div>
                        <mat-error *ngIf="datePurchased.hasError">{{datePurchased.errors}}</mat-error>
                    </mat-form-field>

                </div>
            </div>

            <div class="flex-row justify-start items-center gap-10">
                <div class="pl-3 flex-row gap-10 items-center flex-20">
                    <div>
                        <input type="checkbox" class="checkbox-md"
                            formControlName="forSale" id="forSale" />
                    </div>
                    <label class="checkbox-md" for="forSale">
                        For Sale
                    </label>
                </div>
                <div class="flex-50">
                    <mat-form-field class="dense-3 flex-row justify-start items-center gap-5" subscriptSizing="dynamic" *ngIf="forSale.value">
                        <mat-label> Date For Sale</mat-label>
                        <div class="flex-row justify-between items-center">
                            <input matInput #ds class="flex-80"
                                matTooltip="When approximately was the unit put up for sale"
                                type="date"
                                placeholder="Date For Sale"
                                formControlName="dateForSale">
                            <mat-icon class="clickable flex-10" (click)="openCalendar(ds)">calendar_month</mat-icon>

                        </div>
                        
                    </mat-form-field>
                    <span *ngIf="forSale.value" class="ml-3">On Sale for {{onSaleForMonths}} months.</span>
                    
                </div>
                <div class="flex-30"></div>

            </div>

            <div class="flex-row justify-start items-center gap-10">

                <div class="pl-3 flex-row gap-10 items-center flex-20">
                    <div>
                        <input type="checkbox" class="checkbox-md"
                            formControlName="sold" id="sold" />
                    </div>
                    <label class="checkbox-md" for="sold">
                        Sold
                    </label>
                </div>

                <div class="flex-60">
                    <mat-form-field class="dense-3 flex-row justify-start items-center gap-5" subscriptSizing="dynamic" *ngIf="sold.value">
                        <mat-label> Date Sold</mat-label>
                        <div class="flex-row justify-between items-center">
                            <input matInput #ds class="flex-80"
                                type="date"
                                placeholder="Date Sold"
                                formControlName="dateSold">
                            <mat-icon class="clickable flex-10" (click)="openCalendar(ds)">calendar_month</mat-icon>

                        </div>
                        <mat-error *ngIf="dateSold.hasError">{{dateSold.errors}}</mat-error>
                    </mat-form-field>
                    <span *ngIf="sold.value" class="ml-3">Sold {{soldMonths}} months ago.</span>
                </div>
                <div class="flex-20"></div>
            </div>

            <div class="flex-row justify-start items-center gap-10" *ngIf="sold.value">

                <div class="pl-3 flex-row gap-10 items-center flex-20">
                    <div>
                        <input type="checkbox" class="checkbox-md"
                            formControlName="paidOut" id="paidOut" />
                    </div>
                    <label class="checkbox-md" for="paidOut">
                        Paid Out In Full
                    </label>
                </div>

                <div class="flex-30">
                    <mat-form-field class="dense-3" *ngIf="!paidOut.value">
                        <mat-label>Sale Amount</mat-label>
                        <input matInput currencyMask [options]="{ prefix: 'R ', inputMode: mode }"
                            matTooltip="Amount for which unit was sold"
                            type="text"
                            onClick="this.select()"
                            placeholder="Sale Amount"
                            formControlName="amountSold">
                    </mat-form-field>
                </div>

                <div class="flex-50">
                    <mat-form-field class="dense-3" *ngIf="!paidOut.value">
                        <mat-label>Amount Paid</mat-label>
                        <input matInput currencyMask [options]="{ prefix: 'R ', inputMode: mode }"
                            matTooltip="Amount paid to you"
                            type="text"
                            onClick="this.select()"
                            placeholder="Amount Paid"
                            formControlName="amountPaid">
                    </mat-form-field>
                    <span class="ml-3">{{amountDue | currency : 'R'}} Due to you.</span>
                </div>
                

            </div>

            <div class="flex-row justify-start items-center gap-10">

                <div class="pl-3 flex-row gap-10 items-center flex-20">
                    <div>
                        <input type="checkbox" class="checkbox-md"
                            formControlName="litigation" id="litigation" />
                    </div>
                    <label class="checkbox-md" for="litigation">
                        In Litigation
                    </label>
                </div>

                <div *ngIf="litigation.value" class="flex-30">
                    <mat-form-field class="dense-3">
                        <mat-label>Legal Representative</mat-label>
                        <input matInput
                            matTooltip="Your attorney's name"
                            type="text"
                            placeholder="Legal Representative"
                            formControlName="legalRepresentative">
                    </mat-form-field>
                </div>

                <div class="pl-3 flex-row gap-10 items-center flex-25" *ngIf="litigation.value">
                    <div>
                        <input type="checkbox" class="checkbox-md"
                            formControlName="litigationWon" id="litigationWon" />
                    </div>
                    <label class="checkbox-md" for="litigationWon">
                        Litigation Won
                    </label>
                </div>

                <div class="pl-3 flex-row gap-10 items-center flex-20" *ngIf="litigation.value">
                    <div>
                        <input type="checkbox" class="checkbox-md"
                            formControlName="litigationLost" id="litigationLost" />
                    </div>
                    <label class="checkbox-md" for="litigationLost">
                        Litigation Lost
                    </label>
                </div>

            </div>

            <div class="flex-row justify-start items-center gap-10">
                <div class="pl-3 flex-row gap-10 items-center flex-20">
                    <div>
                        <input type="checkbox" class="checkbox-md"
                            formControlName="payingLevies" id="payingLevies" />
                    </div>
                    <label class="checkbox-md" for="payingLevies">
                        Paying Levies
                    </label>
                </div>

                <div class="flex-30">
                    <mat-form-field class="dense-3" *ngIf="payingLevies.value">
                        <mat-label>Levies Amount</mat-label>
                        <input matInput currencyMask [options]="{ prefix: 'R ', inputMode: mode }"
                            matTooltip="How much do you pay for levies each month?"
                            type="text"
                            onclick="this.select()"
                            placeholder="Levies"
                            formControlName="amountLevies">
                    </mat-form-field>
                </div>
                <div class="flex-50"></div>
            </div>
        </form>

    </mat-card-content>
    <mat-card-actions class="mt-5 flex-row items-center gap-5">
        <button mat-raised-button (click)="save()">
            <mat-icon color="primary" class="app-button">save</mat-icon>
            Save
        </button>
        <button mat-raised-button (click)="cancel()">
            <mat-icon class="app-button" color="accent">cancel</mat-icon>
            Cancel
        </button>
    </mat-card-actions>
</mat-card>