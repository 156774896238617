import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { ICan, ICanResponse, IUser } from './Interfaces/authentication.interfaces';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { AuthStore } from './Auth.store';
import { HttpClient } from '@angular/common/http';
import { IRole } from '../MasterDataModule/Interfaces/IRole.interface';
import { Injectable } from "@angular/core";
import { PolicyStore } from '../MasterDataModule/Stores/policy.store';
import { RoleService } from '../MasterDataModule/Services/role.service';
import { WebApiService } from '../SharedServices/webapi.service';

@Injectable({
    providedIn: 'root'
})
export class AuthorizationService
{
    private destroyed$ = new Subject<void>();
    private url = "Authorization"
    private user: IUser | null = null;
    private roles: IRole[] = [];

    constructor(
        private http: HttpClient,
        policyStore: PolicyStore,
        userStore: AuthStore,
        roleService: RoleService
    ) 
    {
        userStore.user$.pipe(
            takeUntil(this.destroyed$),
            tap(user => this.user = user),
            switchMap(user =>
            {
                return roleService.getUserRoles(user.id);
            }),
            map(rolesResp =>
            {
                this.roles = rolesResp.roles;
                return rolesResp.roles;
            }),
            switchMap(roles =>
            {
                return policyStore.policies$
            }),
            map(policies => policies.filter(p => (p.subject === this.user?.userName || (this.roles.findIndex(r => r.description === p.subject) > -1)))),
            tap(policies =>
            {

                policies.filter(p => p.effect === 'allow').forEach(p =>
                {
                    const key = `${p.object},${p.action}`;
                    let authChange = this.observables[key];
                    if (!authChange)
                    {
                        authChange = new AuthChange();
                        this.observables[key] = authChange;
                    }
                    authChange._effect = p.effect
                });

                policies.filter(p => p.effect === 'deny').forEach(p =>
                {
                    const key = `${p.object},${p.action}`;
                    let authChange = this.observables[key];
                    if (!authChange)
                    {
                        authChange = new AuthChange();
                        this.observables[key] = authChange;
                    }
                    authChange._effect = p.effect
                });

                policies.forEach(p =>
                {
                    const key = `${p.object},${p.action}`;
                    let authChange = this.observables[key];
                    if (authChange)
                    {
                        authChange._effect$.next(authChange._effect === 'allow');
                    }
                });

            })
        ).subscribe();
    }

    can(request: ICan)
    {
        // this.logger.log("Can by :", caller);
        return this.http.post<ICanResponse>(WebApiService.serverUrl + this.url + '/Can', request);
    }


    observables: { [key: string]: AuthChange } = {};

    canI(action: string, object: string)
    {
        return this.can({object:object, action: action}).pipe(map(resp => resp.success));
    }

    ngOnDestroy(): void
    {
        this.destroyed$.next();
        this.destroyed$.complete();
    }
}

export class AuthChange
{
    _effect: string | undefined = undefined;
    _effect$ = new BehaviorSubject<boolean>(false);
    effect$: Observable<boolean> = this._effect$.asObservable();
}