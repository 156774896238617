import { IUser, IUserResponse } from './Interfaces/authentication.interfaces';

import { HttpClient } from '@angular/common/http';
import { IInvitationVerificationResponse } from '../Interfaces/IInvitationVerificationResponse';
import { IOrganization } from '../Interfaces/IOrganization.interface';
import { IOrganizationVerificationResponse } from './../Interfaces/IOrganizationResponse.interface';
import { Injectable } from '@angular/core';
import { WebApiService } from '../SharedServices/webapi.service';

@Injectable({
    providedIn: 'root'
})
export class OrganizationService
{
    private url = 'Organization';

    constructor(private http: HttpClient) { }

    verify(pin: string)
    {
        return this.http.get<IOrganizationVerificationResponse>(WebApiService.serverUrl + this.url + '/Verify?pin=' + pin);
    }

    verifyInvitation(pin: string)
    {
        return this.http.get<IInvitationVerificationResponse>(WebApiService.serverUrl + this.url + '/VerifyInvitation?pin=' + pin);
    }

    getOrganization()
    {
            return this.http.get<IOrganization>(WebApiService.serverUrl +
                this.url +
                '/GetOrganization').toPromise();
    }

    saveOrganization(org: IOrganization)
    {
            return this.http.put(WebApiService.serverUrl +
                this.url +
                '/UpdateOrganization', { Organization: org }).toPromise();
    }

    inviteUser(user: IUser)
    {
        return this.http.post<IUserResponse>(WebApiService.serverUrl + this.url + '/Inviteuser', user);
    }
}
