import { Observable, ReplaySubject, Subject } from 'rxjs';
import { map, takeUntil, tap, filter } from 'rxjs/operators';

import { Injectable, OnDestroy } from '@angular/core';

import { IBaseResponse } from '../../Interfaces/IBaseResponse.interface';
import { IPolicy } from '../Interfaces/IPolicy';
import { isNonNull } from '../../SharedModule/functions/nonNull.function';
import { PolicyService } from '../Services/policy.service';
import { AuthObjectStore } from './auth-object.store';
import { IAuthObject } from '../Interfaces/IAuthObject';
import { AuthStore } from '../../AuthModule/Auth.store';

@Injectable({providedIn: 'root'})
export class PolicyStore implements OnDestroy
{
    private destroyed$ = new Subject<void>();

    private _policies: IPolicy[];
    private _policies$ = new ReplaySubject<IPolicy[]>(1);
    public readonly policies$: Observable<IPolicy[]> = this._policies$.asObservable().pipe(filter(isNonNull));

    constructor(private policyService: PolicyService, private objectStore: AuthObjectStore) //, auth: AuthStore)
    {
        // this.getObjectTypes();

        // auth.user$.pipe(takeUntil(this.destroyed$))
        // .subscribe(user => {
        //     console.log('policy store got user', user);
        //     if (user)
        //     {
        //         objectStore.initialize();
        //     }
        // });

        // this.objectStore.objects$.pipe(takeUntil(this.destroyed$))
        // .subscribe(objects => {
            this.getPolicies();
        // })

    }

    // getObjectTypes()
    // {
    // }

    private getPolicies()   //objects: IAuthObject[]
    {
        this.policyService.getPolicies()
            .pipe(
                takeUntil(this.destroyed$)
            )
            .subscribe(resp =>
            {
                if (resp.success)
                {
                    this._policies = resp.policies;

                    // this._policies.forEach(p => {
                    //     const typ = objects.find(o => o.object === p.object);
                    //     if (typ)
                    //     {
                    //         p.objectType = typ.typeName    
                    //     }
                    // })


                    this._policies$.next(this._policies);
                }
            });

    }

    public upsertPolicy$(policy: IPolicy)
    {
        if (policy.added)
        {
            return this.addPolicy$(policy)
        }
        else
        {
            return this.updatePolicy$(policy);
        }
    }

    private addPolicy$(policy: IPolicy)
    {
        return this.policyService.addPolicy(policy)
            .pipe(
                takeUntil(this.destroyed$),
                tap(response =>
                {
                    if (response.success)
                    {
                        this._policies.push(response.policy);
                        this._policies$.next(this._policies);
                    }
                }),
                map(resp =>
                {
                    return resp as IBaseResponse;
                })
            );
    }

    private updatePolicy$(policy: IPolicy)
    {
        return this.policyService.updatePolicy(policy)
            .pipe(
                takeUntil(this.destroyed$),
                tap(response =>
                {
                    if (response.success)
                    {
                        const existing = this._policies.find(p => p.id == policy.id);
                        if (existing)
                        {
                            const index = this._policies.indexOf(existing);
                            if (index >= 0)
                            {
                                this._policies.splice(index, 1, response.policy);
                            }
                        }
                        else
                        {
                            this._policies.push(response.policy);
                        }
                        this._policies$.next(this._policies);
                    }
                }),
                map(resp =>
                {
                    return resp as IBaseResponse;
                })
            );
    }

    deletePolicy(policy: IPolicy)
    {
        return this.policyService.deletePolicy(policy)
            .pipe(
                takeUntil(this.destroyed$),
                tap(response =>
                {
                    if (response.success)
                    {
                        const existing = this._policies.find(f => f.id == policy.id);
                        if (existing)
                        {
                            const index = this._policies.indexOf(existing);
                            if (index >= 0)
                            {
                                this._policies.splice(index, 1);
                            }
                        }

                        this._policies$.next(this._policies);
                    }
                }),
                map(resp =>
                {
                    return resp as IBaseResponse;
                })
            );
    }

    ngOnDestroy(): void
    {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

}