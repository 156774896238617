<button mat-icon-button class="mat-elevation-z2 mb-1" (click)="closePdf()"
    tabindex="-1" title="Close"><mat-icon>close</mat-icon></button>
<button mat-icon-button class="mat-elevation-z2 mb-1 ml-1" (click)="deletePdf()"
    tabindex="-2" title="Delete"><mat-icon>delete</mat-icon></button>
<div class="full-height" autofocus>
    <object [data]="data.url | safe"
        type="application/pdf"
        width="100%"
        height="100%">
        <p>Your browser does not support PDFs.</p>
    </object>
</div>
