<div class="home border-blue flex-100">
    <h1>Home - Belveredere Trust / Retirewel</h1>
    <p>This is still under development.</p>
    <p>Suggestions are welcomed.</p>
    <p></p>
    <a
        href="mailto:support@getsoft.co.za?subject=Belvedere Action Home Page suggestions">Send
        Suggestions</a>
</div>
<div class="ml-5">
    <h2>Information Summary</h2>
    <p>Number of registered users: {{info?.numberOfRegisteredUsers}}</p>
    <p>Number of retirement units: {{info?.numberOfRetirementUnits}}</p>
    <p>Number of units sold: {{info?.unitsSold}}</p>
    <p>Number of units paid in full: {{info?.unitsPaidInFull}}</p>
    <p>Number of units partially paid: {{info?.unitsPartiallyPaid}}</p>
    <p>Number of units in litigation: {{info?.unitsInLitigation}}</p>
    <p>Number of units where litigation was won: {{info?.unitsLitigationWon}}</p>
    <p>Number of units where litigation was lost: {{info?.unitsLitigationLost}}</p>
    <p>Number of units not sold but for sale: {{info?.unitsNotSoldButForSale}}</p>
    <p>Number of units for sale but paying levies: {{info?.unitsForSaleButPayingLevies}}</p>
    <p>Total amount owing: {{info?.totalAmountOwing}}</p>
    <p>Total amount paid: {{info?.totalAmountPaid}}</p>
</div>
