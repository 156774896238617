import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { IBaseResponse } from '../../Interfaces/IBaseResponse.interface';
import { InformationService } from '../information.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor
{
    private port: number;
    constructor(private router: Router, private info: InformationService) 
    {
        this.port = (<any>window).agentPort;  //['agentPort'];
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        if (req.url.indexOf('token') > -1 || req.url.indexOf(this.port.toString()) > -1)
        {
            return next.handle(req);
        }

        const authToken = sessionStorage.getItem('token');

        const authReq = req.clone({
            headers: req.headers.set('Authorization', 'Bearer ' + authToken)
        });

        // send cloned request with header to the next handler.
        return next.handle(authReq)
            .pipe(
                tap(resp =>
                {
                    if ('body' in resp && 'success' in resp.body)
                    {
                        if (!resp.body.success && resp.body.messages)
                        {
                            this.info.ShowSnackWarning(resp.body.messages[0]);
                        }
                    }
                    else
                    {
                        // console.log('Response' , resp);
                    }
                }),
                catchError(err =>
                {

                    return this.handleError(err, req);
                })
            );
    }

    private handleError(err: HttpErrorResponse, req: HttpRequest<any>): Observable<any>
    {
        // console.warn('handle error event:', err, req);

        if (err.error instanceof ErrorEvent)
        {
            console.error('Can\'t connect to the internet.', err.error);
            this.info.ShowSnackError('Can\'t connect to the internet.');
        }
        else if (err.error instanceof ProgressEvent)
        {
            if (req.url.indexOf(this.port.toString()) > -1)
            {
                const bas: IBaseResponse = { success: false, messages: [''] };
                return throwError(() => bas);
            }
            console.error('Can\'t connect to the server.', err.error);
            this.info.ShowSnackError('Can\'t connect to the server.');
            return of(false);
        }
        else
        {
            let msg: string;

            switch (err.status)
            {
                case 400:
                    const res = this.handle400(err);
                    console.log(res);
                    return of(new HttpResponse({ body: res} ));
                case 401:
                    this.router.navigateByUrl('auth/login');
                    return of(err);
                case 403:
                    msg = err.message;
                    break;
                case 404:
                    msg = `${req.url} was not found. Please note this and inform support.`;
                    break;
                case 500:
                    msg = 'Internal server error';
                    break;
                case 0:
                    msg = 'Unable to connect to server.';
                    break;
                default:
                    msg = err.message;
                    break;
            }

            if (msg)
            {
                this.info.ShowSnackWarning(msg);
                

                // this.notification.showError(msg);
            }
        }
        return of(false);
        // console.error('re-throwing in http interceptor', err.error);
        // return throwError(err);
    }

    private handle400(err: HttpErrorResponse)
    {
        const baseResponse: IBaseResponse = { success: false, messages: [] };

        Object.keys(err.error.errors).forEach(key =>
        {
            // console.log('400 key:' + key, err.error.errors[key]);
            const keys: [] = err.error.errors[key];

            keys.forEach(message => {
                // console.log('400 m:' + message);
                // const baseMessage: IBaseMessage = {
                //     propertyName: key,
                //     message: message
                // };
                baseResponse.messages.push(message);

            });
        });
        return baseResponse;
    }
}
