import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { isNonNull } from '../../SharedModule/functions/nonNull.function';
import { IAuthObject } from '../Interfaces/IAuthObject';
import { ObjectService } from '../Services/authorization-obect.service';
import { IAuthObjectType } from '../Interfaces/IAuthObjectType';
import { AuthStore } from '../../AuthModule/Auth.store';

@Injectable({ providedIn: 'root' })
export class AuthObjectStore implements OnDestroy
{
    private destroyed$ = new Subject<void>();

    private _objects: IAuthObject[];
    private _objects$ = new BehaviorSubject<IAuthObject[] | null>(null);
    public readonly objects$: Observable<IAuthObject[]> = this._objects$.asObservable().pipe(filter(isNonNull));

    private _objectTypes: IAuthObjectType[];
    private _objectTypes$ = new BehaviorSubject<IAuthObjectType[] | null>(null);
    public readonly objectTypes$: Observable<IAuthObjectType[]> = this._objectTypes$.asObservable().pipe(filter(isNonNull));

    constructor(private service: ObjectService, auth: AuthStore)
    {
        auth.user$
        .pipe(takeUntil(this.destroyed$))
        .subscribe(user => {
            this.initialize();
        });
    }

    initialize()
    {
        this.getObjectTypes();
    }

    private getObjects()
    {
        this.service.getObjects()
            .pipe(
                takeUntil(this.destroyed$)
            )
            .subscribe(resp =>
            {
                if (resp.success)
                {
                    this._objects = resp.objects;
                    this.updateObjectTypeNames();
                    this._objects$.next(this._objects);
                }
            });
    }

    private updateObjectTypeNames()
    {
        this._objects.forEach(o => {
            const tp = this._objectTypes.find(t => t.id === o.objectType);
            if (tp)
            {
                o.typeName = tp.typeName;    
            }
        });
    }

    private getObjectTypes()
    {
        this.service.getObjectTypes()
            .pipe(
                takeUntil(this.destroyed$)
            )
            .subscribe(resp =>
            {
                if (resp.success)
                {
                    this._objectTypes = resp.objectTypes;
                    this._objectTypes$.next(this._objectTypes);
                    this.getObjects();
                }
            });

    }

    ngOnDestroy(): void
    {
        this.destroyed$.next();
        this.destroyed$.complete();
    }
}