import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { IAuthenticationRequest, IPasswordResetRequest, IProfile, IUser, IUsernameReminderRequest } from './Interfaces/authentication.interfaces';
import { Injectable, OnDestroy } from '@angular/core';
import { catchError, filter, take, takeUntil, tap } from 'rxjs/operators';

import { IBaseResponse } from '../Interfaces/IBaseResponse.interface';
import { OrganizationService } from './organization.service';
import { Router } from '@angular/router';
import { UserService } from './user.service';
import { isNonNull } from '../SharedModule/functions/nonNull.function';

@Injectable({
    providedIn: 'root'
})
export class AuthStore implements OnDestroy
{
    private destroyed$ = new Subject<void>();

    private _user: IUser | null = null;
    private _user$ = new BehaviorSubject<IUser | null>(null);

    private _users: IUser[] = [];
    private _users$ = new BehaviorSubject<IUser[] | null>(null);

    public readonly user$: Observable<IUser> = this._user$.asObservable().pipe(filter(isNonNull));
    public readonly users$: Observable<IUser[]> = this._users$.asObservable().pipe(filter(isNonNull));

    constructor(private userService: UserService, private router: Router, private organizationService: OrganizationService)
    {
        const token = sessionStorage.getItem('token');
        // console.log('Auth Store constructed', token);
        if (token)
        {
            this.Getuser();
        }
        else
        {
            this._user = null;
            this._user$.next(this._user);
            // this.router.navigate(['login']);
        }
    }

    public isLoggedIn(): boolean
    {
        const token = sessionStorage.getItem('token');
        if (token)
        {
            return true;
        }

        return false;
    }

    private Getuser()
    {
        // console.log('User requested');
        this.userService.GetUser()
            .pipe(
                takeUntil(this.destroyed$),
                tap(user =>
                {
                    if (!user)
                    {
                        this.router.navigate(['auth/login']);
                    }
                    this._user = user;
                    this._user$.next(this._user);
                    this.Getusers();
                })
            )
            .subscribe();
    }

    public deleteUser(user:IUser)
    {
        return this.userService.deleteUser(user)
        .pipe(
            takeUntil(this.destroyed$),
            tap(resp => {
                if (resp.success)
                {
                    const index = this._users.indexOf(user);
                    this._users.splice(index, 1);
                    this._users$.next(this._users);
                }
            })
        )
    }

    public updateUser(user: IUser)
    {
        const profile: IProfile = {
            userName: user.userName,
            firstName: user.firstName ?? '',
            lastName: user.lastName ?? '',
            mobileNumber: user.mobileNumber ?? '',
            email: user.email ?? '',
            locationId: user.location,
        }
        return this.userService.updateUser(profile)
            .pipe(
                tap(u =>
                {
                    if (user.id == this._user?.id)
                    {
                        this.Getuser();
                    }
                })
            );
    }

    public updateUsers(users: IUser[])
    {
        return this.userService.updateUsers(users)
            .pipe(
                tap(u =>
                {
                    users.forEach(user =>
                    {
                        if (user.id == this._user?.id)
                        {
                            this.Getuser();
                        }
                    });
                })
            );
    }

    public Getusers()
    {
        this.userService.GetUsers()
            .pipe(
                takeUntil(this.destroyed$),
                tap(response =>
                {
                    this._users = response.users;
                    this._users$.next(this._users);
                })
            )
            .subscribe();
    }

    public login(username: string, password: string)
    {
        const request: IAuthenticationRequest =
        {
            userName: username,
            password: password,
        }
        return this.userService.login(request)
            .pipe(
                take(1),
                tap(response =>
                {
                    if (response.success)
                    {
                        sessionStorage.setItem('token', response.access_token);
                        this.Getuser();
                    }
                }),
                catchError((err, tok) =>
                {
                    console.log('err:', err);
                    const resp: IBaseResponse = {
                        success: false,
                        messages: [err.error.error_description]
                        // messages: [{ message: err.error.error_description, propertyName: '' }]
                    };
                    return of(resp);
                })
            );
    }

    public logout()
    {
        sessionStorage.removeItem('token');
        this._user = null;
        this._user$.next(this._user);
        location.reload();
        // this.router.navigate(['login']);
    }

    public resetPassword(username: string, caller: string)
    {
        sessionStorage.removeItem('token');
        const resetRequest: IPasswordResetRequest = {
            userName: username
        };

        return this.userService.passwordReset(resetRequest);
    }

    public remindUsername(emailCell: string)
    {
        const request: IUsernameReminderRequest = {
            emailOrMobile: emailCell
        }
        return this.userService.usernameReminder(request);
    }

    public confirmResetPassword(key: string, newPassword: string)
    {
        return this.userService.confirmPasswordReset(key, newPassword);
    }

    public confirmResetKey(key: string)
    {
        return this.userService.confirmPasswordKey(key);
    }

    ngOnDestroy()
    {
        this.destroyed$.next();
        this.destroyed$.complete();
     }
}
