import { Component, Inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppMaterialModule } from 'src/app/app-material.module';
import { SafePipe } from '../Pipes/safe.pipe';
import { IPdfDocument } from '../interfaces/IPdfDocument';

@Component( {
    selector: 'app-pdf-modal',
    templateUrl: 'pdfModal.component.html',
    styleUrls: ['pdfModal.component.scss'],
    standalone: true,
    imports: [ReactiveFormsModule, SafePipe, AppMaterialModule]
})

export class PdfModalComponent
{
    constructor(@Inject(MAT_DIALOG_DATA) public data: IPdfDocument, public dialogRef: MatDialogRef<PdfModalComponent>) {
     }

     closePdf()
     {
        this.dialogRef.close('ok');
     }
     
     deletePdf()
     {
        this.dialogRef.close('delete');
     }
}