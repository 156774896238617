import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { AuthStore } from './Auth.store';
import { AuthorizationService } from './Authorization.service';
import { ICan } from './Interfaces/authentication.interfaces';
import { InformationService } from '../SharedServices/information.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { take } from 'rxjs/operators';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AppGuard implements CanActivate
{
    constructor(
        private router: Router,
        private auth: AuthStore,
        private authorization: AuthorizationService,
        private notifications: InformationService
    )
    { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree 
    {
        if (!this.auth.isLoggedIn())
        {
            this.router.navigate(['auth/login']);
            return false;
        }

        if (next.routeConfig?.path == 'home')
        {
            return true;
        }

        const request: ICan = {
                object: next.routeConfig?.path ?? '',
                action: 'navigate'
        }

        return this.authorization.can(request)
            .pipe(
                take(1),
                tap(resp =>
                {
                    if (!resp.success) 
                    {
                        this.notifications.ShowSnackWarning(`Access to ${request.object} denied.`);
                        // if (next.routeConfig.path !== 'home') {
                        //     this.router.navigate(['home']);
                        // }
                        // else{
                        //     this.router.navigate(['notfound']);
                        // }
                    }
                }),
                map(resp => resp.success)
            );
    }
}
