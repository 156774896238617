import { DialogService } from './../SharedServices/dialog.service';
import { AuthStore } from './../AuthModule/Auth.store';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { Subject } from 'rxjs';
import { CaptionService } from '../SharedServices/title.service';
import { takeUntil } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { BarChartComponent } from '../SharedModule/Components/bar-chart/bar-chart.component';
import { InformationService } from '../MasterDataModule/Services/info-service';
import { IInformationResponse } from '../MasterDataModule/Interfaces/IInformationResponse';

@Component({
    selector: 'app-home',
    templateUrl: 'home.component.html',
    styleUrls: ['home.component.scss'],
    standalone: true,
    imports: [CommonModule, MatCardModule, BarChartComponent]
})
export class HomeComponent implements OnDestroy, OnInit
{
    private destroyed$ = new Subject<void>();
    info: IInformationResponse;

    constructor(title: CaptionService, private dialogs: DialogService, private infoService: InformationService)
    {
        title.setTitle('Information');        


    }

    ngOnInit()
    {
        this.infoService.getInformation().pipe(takeUntil(this.destroyed$))
        .subscribe(resp => {
            if (resp.success) 
            {
                this.info = resp;
            }
            else
            {
                this.dialogs.showMessages('Get Information Failed', resp.messages, 0.6);
            }
        })
    }

    ngOnDestroy()
    {
        this.destroyed$.next();
        this.destroyed$.complete();
    }
}