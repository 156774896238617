import { IUnitsResponse } from './../Interfaces/IUnitsResponse';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WebApiService } from '../../SharedServices/webapi.service';
import { IBaseResponse } from '../../Interfaces/IBaseResponse.interface';
import { LoadingService } from "../../SharedServices/loading.service";
import { IUnit } from "../Interfaces/IUnit";
import { IUnitAddRequest } from "../Interfaces/IUnitAddRequest";
import { IUnitResponse } from '../Interfaces/IUnitResponse';

@Injectable({
    providedIn: 'root'
})
export class UnitService {

    private unitssUrl = 'Unit';

    constructor(private http: HttpClient, private loading: LoadingService) { }

    public getUnits()
    {
        const obs$ =   this.http.get<IUnitsResponse>(WebApiService.serverUrl + this.unitssUrl + '/GetUnits');
        return this.loading.showUntilCompleted(obs$, 'Getting units...');
    }

    addUnit(unit: IUnitAddRequest)
    {
        const obs$ = this.http.post<IUnitResponse>(WebApiService.serverUrl + this.unitssUrl + '/AddUnit', unit);
        return this.loading.showUntilCompleted(obs$, 'Adding unit...');
    }

    updateUnit(unit: IUnit)
    {
        const obs$ = this.http.post<IUnitResponse>(WebApiService.serverUrl + this.unitssUrl + '/UpdateUnit', unit);
        return this.loading.showUntilCompleted(obs$, 'Updating unit...');
    }


    deleteUnit(unit: IUnit)
    {
        const obs$ = this.http.post<IBaseResponse>(WebApiService.serverUrl + this.unitssUrl + '/DeleteUnit', unit);
        return this.loading.showUntilCompleted(obs$, 'Deleting unit...');
    }
}