import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppMaterialModule } from 'src/app/app-material.module';
import { IMessage } from '../interfaces/shared.interfaces';

@Component( {
    selector: 'app-input-modal',
    templateUrl: 'inputModal.component.html',
    styleUrls: ['inputModal.component.scss'],
    standalone: true,
    imports: [ReactiveFormsModule, AppMaterialModule]
})

export class InputModalComponent
{
    // noteText: string;

    loginForm = new FormGroup(
        {
            input: new FormControl(''),
        }
    );

    get input() { return this.loginForm.get('input')!; }

    constructor(@Inject(MAT_DIALOG_DATA) public data: IMessage, public dialogRef: MatDialogRef<InputModalComponent>)
    { 
    }

     cancel()
     {
         this.dialogRef.close();
     }

     save()
     {
        this.dialogRef.close(this.input.value);
     }
}
