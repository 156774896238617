<mat-card class="p-5 m-5 mat-elevation-z8">
    <div class="flex-row justify-start items-center gap-10">
        <button mat-mini-fab
            (click)="addUnit()"
            matTooltip="Add Another Unit"
            class="type-new">
            <mat-icon>add</mat-icon>
        </button>
        <span>Click this to add a Belvedere unit that you own</span>
    </div>
    <div class="scrollable">
        <table mat-table
            matSort
            [dataSource]="dataSource">

            <tr mat-header-row
                *matHeaderRowDef="displayedColumns; sticky: true"></tr>

            <ng-container matColumnDef="villageName">
                <th mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header> Village </th>
                <td mat-cell class="flex-row justify-start items-center"
                    *matCellDef="let unit">
                    <button mat-icon-button
                        matTooltip="Click this to delete the unit"
                        (click)="deleteUnit(unit)">
                        <mat-icon color="accent">delete</mat-icon>
                    </button>
                    <a href="#"
                        (click)="selectUnit($event, unit)">
                        {{unit.villageName}}
                    </a>

                </td>
            </ng-container>

            <ng-container matColumnDef="unitNumber">
                <th mat-header-cell
                    *matHeaderCellDef> Unit Number </th>
                <td mat-cell
                    *matCellDef="let unit">
                    {{unit.unitNumber}}
                </td>
            </ng-container>

            <ng-container matColumnDef="forSale">
                <th mat-header-cell
                    *matHeaderCellDef> For Sale </th>
                <td mat-cell
                    *matCellDef="let unit">
                    {{unit.forSale ? "Yes" : "No"}}
                </td>
            </ng-container>

            <ng-container matColumnDef="sold">
                <th mat-header-cell
                    *matHeaderCellDef> Sold </th>
                <td mat-cell
                    *matCellDef="let unit">
                    {{unit.sold ? "Yes" : "No"}}
                </td>
            </ng-container>

            <ng-container matColumnDef="litigation">
                <th mat-header-cell
                    *matHeaderCellDef> In Litigation </th>
                <td mat-cell
                    *matCellDef="let unit">
                    {{unit.litigation ? "Yes" : "No"}}
                </td>
            </ng-container>

            <ng-container matColumnDef="payingLevies">
                <th mat-header-cell
                    *matHeaderCellDef> Paying Levies </th>
                <td mat-cell
                    *matCellDef="let unit">
                    {{unit.payingLevies ? "Yes" : "No"}}
                </td>
            </ng-container>

            <tr mat-row
                *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</mat-card>