import { IBaseResponse } from '../../../Interfaces/IBaseResponse.interface';
import { Component, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { CaptionService } from '../../../SharedServices/title.service';
import { map, take, takeUntil } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { UnitsStore } from '../../Stores/units.store'
import { IUnit } from '../../Interfaces/IUnit';
import { MatTableDataSource } from '@angular/material/table';
import { AppMaterialModule } from '../../../app-material.module';
import { MatSort } from '@angular/material/sort';
import { DialogService } from '../../../SharedServices/dialog.service';
import { UnitComponent } from '../unit/unit.component';

@Component({
    selector: 'app-units',
    templateUrl: 'my-units.component.html',
    styleUrls: ['my-units.component.scss'],
    standalone: true,
    imports: [CommonModule, AppMaterialModule]
})
export class UnitsComponent
{
    private destroyed$ = new Subject<void>();

    @ViewChild(MatSort) sort: MatSort;

    data: IUnit[];
    displayedColumns: string[] = ['villageName', 'unitNumber', 'forSale', 'sold', 'litigation', 'payingLevies'];
    dataSource: MatTableDataSource<IUnit>;

    constructor(title: CaptionService, private unitStore: UnitsStore, private dialogs: DialogService)
    {
        title.setTitle('My Belvedere Units');

        unitStore.units$
            .pipe(
                takeUntil(this.destroyed$)
            )
            .subscribe(
                resp =>
                {
                    this.data = resp;
                    this.displayData();
                }
            );

    }

    displayData()
    {
        this.dataSource = new MatTableDataSource<IUnit>(this.data.sort((a, b) => {
            if (a.villageName < b.villageName) {
                return -1;
            }
            if (a.villageName > b.villageName) {
                return 1;
            }
            if (a.unitNumber && b.unitNumber && a.unitNumber < b.unitNumber) {
                return -1;
            }
            if (a.unitNumber && b.unitNumber && a.unitNumber > b.unitNumber) {
                return 1;
            }

            return 0;
        }));
        this.dataSource.sort = this.sort;

        // setTimeout(() =>
        // {
        //     this.sort.sort({ id: 'villageName', start: 'asc', disableClear: true })
        // }, 1);

    }

    addUnit()
    {
        let unit: IUnit = <IUnit>{};
        this.editUnit(unit);
    }

    deleteUnit(unit: IUnit)
    {
        this.dialogs.ask('Please Confirm', `Are you sure you want to delete ${unit.villageName} unit ${unit.unitNumber?unit.unitNumber:""}? It can't be undone.`)
        .pipe(take(1))
        .subscribe(res => {
            if (res ==='ok') {
                this.unitStore.deleteUnit(unit).pipe(takeUntil(this.destroyed$))
                .subscribe(resp => {
                    if (!resp.success) {
                        this.dialogs.showMessages('Delete Failed', resp.messages);
                    }
                });
            }
        });
    }

    editUnit(unit: IUnit)
    {
        return this.dialogs.ShowComponent(UnitComponent, unit, 0.80)
            .pipe(
                take(1),
                map(result => result as IUnit)
            )
            .subscribe(unit =>
            {
                if (!unit)
                {
                    return;
                }

                let response$: Observable<IBaseResponse>;

                if (unit.id)
                {
                    response$ = this.unitStore.updateUnit$(unit)
                }
                else
                {
                    response$ = this.unitStore.addUnit$(unit)
                }

                response$.pipe(
                    take(1)
                )
                    .subscribe(resp =>
                    {
                        if (resp.success)
                        {
                            this.dialogs.showMessage('Saved', 'Your changes have been saved.', 0.4);
                        }
                        else
                        {
                            this.dialogs.showMessage('Save Failed', resp.messages[0], 0.5);
                        }
                    });
            });
    }

    selectUnit(event: MouseEvent, unit: IUnit)
    {
        event.stopPropagation();
        this.editUnit(unit);
        return false;
    }
}