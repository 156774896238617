import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WebApiService } from '../../SharedServices/webapi.service';
import { IBaseResponse } from '../../Interfaces/IBaseResponse.interface';
import { LoadingService } from '../../SharedServices/loading.service';
import { IPoliciesResponse } from '../Interfaces/IPoliciesResponse';
import { IPolicy } from '../Interfaces/IPolicy';
import { IPolicyResponse } from '../Interfaces/IPolicyResponse';

@Injectable({providedIn: 'root'})
export class PolicyService {

    private policiesUrl = 'Policy';

    constructor(private http: HttpClient, private loading: LoadingService) { }

    public getPolicies()
    {
        const obs$ =   this.http.get<IPoliciesResponse>(WebApiService.serverUrl + this.policiesUrl + '/GetPolicies');
        return this.loading.showUntilCompleted(obs$, 'Getting policies...');
    }

    addPolicy(policy: IPolicy)
    {
        const obs$ = this.http.post<IPolicyResponse>(WebApiService.serverUrl + this.policiesUrl + '/AddPolicy', {policy: policy});
        return this.loading.showUntilCompleted(obs$, 'Adding policy...');
    }

    updatePolicy(policy: IPolicy)
    {
        const obs$ = this.http.post<IPolicyResponse>(WebApiService.serverUrl + this.policiesUrl + '/UpdatePolicy', {policy: policy});
        return this.loading.showUntilCompleted(obs$, 'Updating policy...');
    }

    deletePolicy(policy: IPolicy)
    {
        const obs$ = this.http.post<IBaseResponse>(WebApiService.serverUrl + this.policiesUrl + '/DeletePolicy', {policy: policy});
        return this.loading.showUntilCompleted(obs$, 'Deleting policy...');
    }
}