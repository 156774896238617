<div class="container flex-row items-stretch">
    <div class></div>
    <div #screen class="flex-100 flex-column">
        <div #caption class="primary-b-color flex-row justify-between items-center p-5">
            <div class="app-caption">
                <button (click)="home($event)"
                    matTooltip="Show or Hide the main menu"
                    mat-mini-fab tabindex="-1">
                    <mat-icon>menu</mat-icon>
                </button>
                <button *ngIf="userName"
                    matTooltip="Logout of the system"
                    (click)="logoff($event)"
                    mat-mini-fab>
                    <mat-icon>lock</mat-icon>
                </button>
                <span class="hello caption">{{captionService.title$ | async}}</span>
                <div *ngIf="operation"
                    fxLayout="row"
                    fxLayoutAlign="start center">
                    <img src="../assets/spinOnBlue20x20.svg">
                    <span class="app-operation">{{operation}}</span>
                </div>
            </div>

            <div class="app-user" *ngIf="user">
                Hello <span class="ml-2 app-white-link"
                    (click)="showProfile($event)">
                    {{user.firstName ?? user.userName}}</span>
            </div>
        </div>
        <app-side-menu [isOpen]="isOpen">

            <mat-menu #masterDataMenu="matMenu"
                class="ml-5"
                xPosition="after">
                <mat-nav-list>
                    <a mat-list-item
                        *ngIf="canSeeDocumentTypes | async"
                        matTooltip="View and edit document types"
                        matTooltip="Edit document types"
                        routerLink="master/docTypes"
                        class="mt-1">
                        <div class="flex-row items-center">
                            <mat-icon>description</mat-icon>
                            <span class="ml-2">Document Types</span>
                        </div>
                    </a>
                    <a mat-list-item
                        *ngIf="canSeeRoles | async"
                        matTooltip="View and edit system roles"
                        routerLink="master/roles">
                        <div class="flex-row items-center">
                            <mat-icon>people_outline</mat-icon>
                            <span class="ml-2">Roles</span>
                        </div>
                    </a>
                    <a mat-list-item
                        *ngIf="canSeeUsers | async"
                        matTooltip="View and edit users"
                        routerLink="master/users">
                        <div class="flex-row items-center">
                            <mat-icon>people_alt</mat-icon>
                            <span class="ml-2">Users</span>
                        </div>
                    </a>
                    <a mat-list-item
                        *ngIf="canSeePolicies | async"
                        matTooltip="View and edit authorization policies"
                        routerLink="master/policies">
                        <div class="flex-row items-center">
                            <mat-icon>policy</mat-icon>
                            <span class="ml-2">Authorization Policies</span>
                        </div>
                    </a>
                </mat-nav-list>
            </mat-menu>

            <mat-nav-list>

                <a mat-list-item
                    matTooltip="View the home screen"
                    (click)="saveNavigation('home')"
                    routerLink="/home"
                    class="mt-1">
                    <div class="flex-row items-center">
                        <mat-icon class="app-button">home</mat-icon>
                        <span class="ml-2" routerLinkActive="active-route">Home</span>
                    </div>
                </a>

                <a mat-list-item
                    *ngIf="canSeeMaster | async"
                    matTooltip="View and Edit master data"
                    [matMenuTriggerFor]="masterDataMenu">
                    <div class="flex-row items-center">
                        <mat-icon>storage</mat-icon>
                        <span class="ml-2">Master Data</span>
                    </div>
                </a>

                <a mat-list-item
                    *ngIf="canSeeUnits | async"
                    matTooltip="View and Edit my units"
                    routerLink="/units">
                    <div class="flex-row items-center">
                        <mat-icon>gite</mat-icon>
                        <span class="ml-2">My Units</span>
                    </div>
                </a>

                <a mat-list-item *ngIf="user"
                    fxFlexAlign="end"
                    matTooltip="Logout of the system"
                    (click)="logoff($event);"
                    class="mt-5">
                    <div class="flex-row items-center">
                        <mat-icon>lock_open</mat-icon>
                        <span class="ml-2">Logout</span>
                    </div>
                </a>
            </mat-nav-list>

        </app-side-menu>

    </div>
</div>