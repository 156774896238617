import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WebApiService } from '../../SharedServices/webapi.service';
import { LoadingService } from "../../SharedServices/loading.service";
import { IAuthObjectsResponse } from '../Interfaces/IAuthObjectsResponse';
import { IAuthObjectTypesResponse } from '../Interfaces/IAuthObjectTypesResponse';

@Injectable({
    providedIn: 'root'
})
export class ObjectService {

    private url = 'AuthObject';

    constructor(private http: HttpClient, private loading: LoadingService) { }

    public getObjects()
    {
        const obs$ =   this.http.get<IAuthObjectsResponse>(WebApiService.serverUrl + this.url + '/GetObjects');
        return this.loading.showUntilCompleted(obs$, 'Getting auth objects...');
    }

    public getObjectTypes()
    {
        const obs$ =   this.http.get<IAuthObjectTypesResponse>(WebApiService.serverUrl + this.url + '/GetObjectTypes');
        return this.loading.showUntilCompleted(obs$, 'Getting auth object types...');
    }
}
