import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule, UntypedFormControl, Validators } from '@angular/forms';
import { take, tap } from 'rxjs/operators';

import { AppMaterialModule } from '../../../app-material.module';
import { AuthStore } from '../../Auth.store';
import { CommonModule } from '@angular/common';
import { DialogService } from '../../../SharedServices/dialog.service';

@Component({
    selector: 'app-recovery',
    templateUrl: 'recovery.component.html',
    styleUrls: ['recovery.component.scss'],
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, FormsModule, AppMaterialModule]
})
export class RecoveryComponent implements OnInit
{
    recoveryForm = new FormGroup(
        {
            email: new UntypedFormControl(''),
            key: new UntypedFormControl('', Validators.required),
            password: new UntypedFormControl(''),
            userName: new UntypedFormControl(''),
        }
    );

    get email() { return this.recoveryForm.get('email')!; }
    get key() { return this.recoveryForm.get('key')!; }
    get password() { return this.recoveryForm.get('password')!; }
    get userName() { return this.recoveryForm.get('userName')!; }

    forgotName = false;
    forgotPassword = true;
    keyRequired = false;
    newPasswordRequired = false;

    confirmedkey: string;
    loading = false;
    message: string = '';
    mobile: boolean;

    get isMobile() { return this.mobile; }

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private userStore: AuthStore,
        private dialog: DialogService
    )
    {
    }

    checkChanged(box: string)
    {
        if (box === 'name')
        {
            this.forgotPassword = !this.forgotName;
        }

        if (box === 'password')
        {
            this.forgotName = !this.forgotPassword;
        }
    }

    reset(event: Event)
    {
        event.stopPropagation();

        if (this.newPasswordRequired)
        {
            this.confirmPasswordReset();
            return false;
        }
        if (this.forgotPassword)
        {
            this.passwordReset();
            return false;
        }
        if (this.forgotName)
        {
            this.userNameReminder();
            return false;
        }

        return false;
    }

    confirmPasswordReset()
    {
        this.userStore.confirmResetPassword(this.confirmedkey, this.password.value)
            .pipe(
                take(1),
                tap(resp =>
                {
                    if (resp.success)
                    {
                        const title = 'Reset Success';
                        this.dialog.waitMessage(title, resp.messages[0])
                            .pipe(take(1))
                            .subscribe(_ => this.router.navigate(['auth/login']));
                    }
                    else
                    {
                        this.dialog.showMessage('Reset Failed', resp.messages[0]);
                    }
                })
            )
            .subscribe();
    }


    passwordReset()
    {
        this.userStore.resetPassword(this.userName?.value, 'Login Component')
            .pipe(
                take(1),
                tap(resp =>
                {
                    if (resp.success)
                    {
                        const title = 'Reset Success';
                        this.dialog.showMessage(title, resp.messages[0]);
                        this.keyRequired = true;
                    }
                    else
                    {
                        this.dialog.showMessage('Reset Failed', resp.messages[0]);
                    }
                })
            )
            .subscribe();
    }

    userNameReminder()
    {
        this.userStore.remindUsername(this.email?.value)
            .pipe(
                take(1),
                tap(resp =>
                {
                    if (resp.success)
                    {
                        const title = 'Success';
                        this.dialog.waitMessage(title, resp.messages[0])
                            .pipe(take(1))
                            .subscribe(_ => this.router.navigate(['auth/login']));
                    }
                    else
                    {
                        this.dialog.showMessage('Failed', resp.messages[0]);
                    }
                })
            )
            .subscribe();
    }

    validateKey(event: Event)
    {
        event.stopPropagation();

        this.userStore.confirmResetKey(this.key.value)
            .subscribe(resp =>
            {
                if (resp.success)
                {
                    this.keyRequired = false;
                    this.newPasswordRequired = true;
                    this.confirmedkey = this.key?.value;
                }
                else
                {
                    if (resp.messages[0])
                    {
                        this.dialog.showMessages('Recovery', resp.messages);
                    }
                }
            });
        return false;
    }

    signIn(event: MouseEvent)
    {
        if (event)
        {
            event.stopPropagation();
        }
        this.router.navigate(['auth/login']);
        return false;
    }

    toLogin(event: MouseEvent)
    {
        event.stopPropagation();
        this.router.navigate(['auth/login']);
        return false;
    }

    toRegister(event: MouseEvent)
    {
        event.stopPropagation();
        this.router.navigate(['auth/register']);
        return false;
    }

    ngOnInit(): void
    {
        this.route.params
            .subscribe(params =>
            {
                console.log('params', params);
                if (params['user'] && params['key'])
                {
                    this.keyRequired = false;
                    this.newPasswordRequired = true;
                    this.confirmedkey = params['key'];
                }
                else
                {
                    // this.validateKey();
                }
            });
    }
}
