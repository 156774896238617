import { ColorModalComponent } from '../SharedModule/Modals/color-picker/colorModal.component';
import { IBaseResponse } from '../Interfaces/IBaseResponse.interface';
import { IPdfDocument } from '../SharedModule/interfaces/IPdfDocument';
import { Injectable } from '@angular/core';
import { InputModalComponent } from '../SharedModule/Modals/inputModal.component';
import { MatDialog } from '@angular/material/dialog';
import { MessageModalComponent } from '../SharedModule/Modals/messageModal.component';
import { MessagesModalComponent } from '../SharedModule/Modals/messagesModal.component';
import { PdfModalComponent } from '../SharedModule/Modals/pdfModal.component';
import { take } from 'rxjs/operators';

@Injectable({providedIn:'root'})
export class DialogService
{
    constructor(public dialog: MatDialog)
    {

    }

    public ShowColorPicker(color: any)
    {
        return this.dialog.open(ColorModalComponent,
            {
                minHeight: 500,
                width: `580px`,
                data: color
            })
            .afterClosed()
            .pipe(
                take(1)
            );
    }

    public ShowComponent(component: any, data: any, width: number = 0.65)
    {
        return this.dialog.open(component,
            {
                width: `${window.innerWidth * width}px`,
                panelClass: 'custom-dialog',
                data: data
            })
            .afterClosed()
            .pipe(
                take(1)
            );
    }


    public showPdf(document: IPdfDocument)
    {
        return this.dialog.open(PdfModalComponent,
            {
                data: {
                    title: document.title, 
                    url: document.url,
                    showCancel: false
                },
                width: `${window.innerWidth * 0.9}px`,
                height: `${window.innerHeight * 0.9}px`
            }).afterClosed();
    }

    public showMessage(title: string = 'Oops', message: string, width: number = 0.65)
    {
        this.dialog.open(MessageModalComponent,
            {
                data: {
                    title: title, 
                    message: message,
                    showCancel: false
                },
                width: `${window.innerWidth * width}px`
            });
    }


    // to do: show difference between success and error
    public showResponse(title: string = 'Oops', action: string, response: IBaseResponse, width: number = 0.65)
    {
        this.dialog.open(MessagesModalComponent,
            {
                data: {
                    title: title, 
                    messages: response.messages,
                    showCancel: false
                },
                width: `${window.innerWidth * width}px`
            });
    }

    public showMessages(title: string = 'Oops', messages: string[], width: number = 0.65)
    {
        // let strings: string[] = [];
        // messages.forEach(msg => {
        //     strings.push(msg.message);
        // });

        this.dialog.open(MessagesModalComponent,
            {
                data: {
                    title: title, 
                    messages: messages,
                    showCancel: false
                },
                width: `${window.innerWidth * width}px`
            });
    }

    public waitMessage(title: string = 'Oops', message: string, width: number = 0.8)
    {
        return this.dialog.open(MessageModalComponent,
            {
                data: {
                    title: title, message: message,
                    showCancel: false
                },
                width: `${window.innerWidth * width}px`
            })
            .afterClosed();
    }

    public waitMessages(title: string = 'Oops', messages: string[])
    {
        return this.dialog.open(MessagesModalComponent,
            {
                data: {
                    title: title, messages: messages,
                    showCancel: false
                },
                width: `${window.innerWidth * 0.8}px`
            })
            .afterClosed();
    }

    ask(title: string, message: string, width: number = 0.6)
    {
        return this.dialog.open(MessageModalComponent, 
            {
                data: {
                    title: title,
                    message: message,
                    showCancel: true
                },
                width: `${window.innerWidth * width}px`
            })
            .afterClosed();
    }

    input(title: string, message: string, width: number = 0.8)
    {
        return this.dialog.open(InputModalComponent, 
            {
                data: {
                    title: title,
                    message: message,
                    showCancel: true
                },
                width: `${window.innerWidth * width}px`,
                // height: `${window.innerHeight * 0.5}px`
            })
            .afterClosed();
    }

}
