<div class="p-10">
    <h2 mat-dialog-title>{{data.title}}</h2>

    <div mat-dialog-content
        class="mt-3">
        <div *ngFor="let msg of errorInfo.messages">
            <p>{{msg}}</p>
        </div>

    </div>
    <div mat-dialog-actions class="flex-row gap-5 mt-3">
        <button mat-raised-button
            color="primary"
            class="mat-elevation-z2"
            (click)="ok()"
            cdkFocusInitial>Ok
        </button>
        <button mat-raised-button
            *ngIf="data.showCancel"
            class="mat-elevation-z2"
            (click)="cancel()"
            cdkFocusInitial>Cancel
        </button>
    </div>
</div>