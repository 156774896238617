import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { take, tap } from 'rxjs/operators';

import { IOrganization } from '../../../Interfaces/IOrganization.interface';
import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';
import { UserService } from '../../user.service';
import { DialogService } from '../../../SharedServices/dialog.service';

@Component({
    selector: 'app-verify-invitation',
    templateUrl: 'verify-invitation.component.html',
    styleUrls: ['verify-invitation.component.scss'],
    standalone: true,
    imports: [CommonModule, MatCardModule]
})

export class VerifyInvitationComponent implements OnInit
{
    organization: IOrganization | null;

    message: string = 'Waiting for verification...';
    loading = false;
    resetKey: string;
    userName: string;
    subscription: any;

    constructor(
        private router: Router,
        private dialogs: DialogService,
        private userService: UserService,
        private route: ActivatedRoute)
    {
    }

    reset()
    {
        this.router.navigate(['recover', this.userName, this.resetKey]);
    }

    async tryVerifyPin(pin: string)
    {
        this.message = '';
        this.userService.verifyInvitation(pin)
            .pipe(
                take(1),
                tap(response =>
                {
                    console.log('verification:', response);
                    if (response.success)
                    {
                        console.log('organization:', this.organization);
                        this.dialogs.showMessage('Verification Succeeeded', 'Please login', 0.3);
                    }
                    else
                    {
                        this.dialogs.showMessages('Verification Failed', response.messages, 0.4);
                        this.message = response.messages[0];
                        this.organization = null;
                    }
                })
            )
            .subscribe();
    }

    ngOnInit(): void
    {
        this.subscription = this.route.params.subscribe(params =>
        {
            this.tryVerifyPin(params['pin']);
        });
    }
}
