<div class="p-10">
    <h2 mat-dialog-title>{{data.title}}</h2>

    <div mat-dialog-content
        class="mb-4">
        {{data.message}}
    </div>
    <div mat-dialog-actions class="flex-row gap-5">
        <button mat-raised-button class="" (click)="ok()">
            <mat-icon color="primary" class="app-button">check</mat-icon>
            Ok
        </button>
        <button mat-raised-button *ngIf="data.showCancel" class="mat-elevation-z2" (click)="cancel()" autofocus>
            <mat-icon color="accent" class="app-button">cancel</mat-icon>
            Cancel
        </button>
    </div>
</div>