import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ColorEvent, RGBA } from 'ngx-color';
import { ColorSketchModule } from 'ngx-color/sketch'; // <color-sketch></color-sketch>

@Component({
   selector: 'app-pdf-modal',
   templateUrl: 'colorModal.component.html',
   styleUrls: ['colorModal.component.scss'],
   standalone: true,
   imports: [CommonModule, ColorSketchModule, ColorModalComponent, MatButtonModule]
})

export class ColorModalComponent
{
   hexColor: string;
   rgbColor: string;
   constructor(@Inject(MAT_DIALOG_DATA) public data: string, public dialogRef: MatDialogRef<ColorModalComponent>) 
   {
      this.hexColor = data;
   }
   
   change(event: ColorEvent)
   {
      this.hexColor = event.color.hex;
      this.rgbColor = `rgba(${event.color.rgb.r}, ${event.color.rgb.g}, ${event.color.rgb.b}, ${event.color.rgb.a})`;
      console.log('RGBA = ' + this.rgbColor);
   }

   ok()
   {
      this.dialogRef.close(this.rgbColor);
   }

   cancel()
   {
      this.dialogRef.close();
   }
}