import { BehaviorSubject, Observable, Subject } from "rxjs";
import { Injectable, OnDestroy } from "@angular/core";

@Injectable({providedIn:'root'})
export class CaptionService implements OnDestroy
{
    private title = '';
    private titleSubject = new BehaviorSubject<string>('');
    title$: Observable<string> = this.titleSubject.asObservable();

    private _sideNavExpanded = true;
    private _sideNavExpanded$ = new BehaviorSubject<boolean>(this._sideNavExpanded);
    sideNavExpanded$: Observable<boolean> = this._sideNavExpanded$.asObservable();

    private _captionHeight = 50;
    private _captionHeight$ = new BehaviorSubject<number>(this._captionHeight);
    captionHeight$: Observable<number> = this._captionHeight$.asObservable();

    private destroyed$ = new Subject<void>();

    constructor()
    {
    }

    setCaptionHeight(height: number, caller: string = "")
    {
        if (caller === "" || caller !== 'App') {
            return;
        }
        this._captionHeight = height;
        this._captionHeight$.next(this._captionHeight);
    }

    setExpanded(value: boolean)
    {
        this._sideNavExpanded = value;
        this._sideNavExpanded$.next(this._sideNavExpanded);
    }

    setTitle(title: string)
    {
        this.title = title;
        this.titleSubject.next(this.title);
    }

    ngOnDestroy(): void
    {
        this.destroyed$.next();
        this.destroyed$.complete();
    }
}