import { IAuthenticateResponse, IAuthenticationRequest, IChangePasswordRequest, IPasswordResetRequest, IProfile, IRegisterRequest, IUser, IUsernameReminderRequest, IUsersResponse } from './Interfaces/authentication.interfaces';

import { HttpClient } from '@angular/common/http';
import { IBaseResponse } from '../Interfaces/IBaseResponse.interface';
import { Injectable } from '@angular/core';
import { LoadingService } from '../SharedServices/loading.service';
import { WebApiService } from '../SharedServices/webapi.service';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    private userUrl = 'User';  // URL to web api

    constructor(private http: HttpClient, private loading: LoadingService) { }

    public GetUser()
    {
        const obs$ = this.http.get<IUser>(WebApiService.serverUrl + this.userUrl + '/GetCurrentUser');
        return this.loading.showUntilCompleted(obs$, 'Getting user');
    }

    public GetUsers()
    {
        const obs$ =   this.http.get<IUsersResponse>(WebApiService.serverUrl + this.userUrl + '/GetUsers');
        return this.loading.showUntilCompleted(obs$, 'Get users');
    }

    register(request: IRegisterRequest) {
        const obs$ =  this.http.post<IBaseResponse>(WebApiService.serverUrl + this.userUrl + '/Register', request);
        return this.loading.showUntilCompleted(obs$, 'Registering...');
    }

    verifyInvitation(key: string)
    {
        return this.http.get<IBaseResponse>(WebApiService.serverUrl + this.userUrl + '/VerifyInvitation?key=' + key);
    }


    deleteUser(user: IUser)
    {
        const obs$ =  this.http.post<IBaseResponse>(WebApiService.serverUrl + this.userUrl + '/DeleteUser', { userId: user.id });
        return this.loading.showUntilCompleted(obs$, 'Deleting user');
    }

    updateUser(profile: IProfile) {
        const obs$ =  this.http.put<IBaseResponse>(WebApiService.serverUrl + this.userUrl + '/UpdateProfile', profile);
        return this.loading.showUntilCompleted(obs$, 'Updating user');
    }

    updateUsers(users: IUser[]) {
        const obs$ =  this.http.put<IBaseResponse>(WebApiService.serverUrl + this.userUrl + '/UpdateUsers', users);
        return this.loading.showUntilCompleted(obs$, 'Updateting users');
  }

    updatePassword(request: IChangePasswordRequest) {
        const obs$ =  this.http.put<string>(WebApiService.serverUrl + this.userUrl + '/UpdatePassword', request);
        return this.loading.showUntilCompleted(obs$, 'Updating password');
    }

    login(request: IAuthenticationRequest){
        const frm = new FormData;
        frm.append('grant_type', 'password');
        frm.append('username', request.userName);
        frm.append('password', request.password);

        const obs$ = this.http.post<IAuthenticateResponse>(WebApiService.serverUrl + 'User/Authenticate', frm);
        return this.loading.showUntilCompleted(obs$, 'Authenticating...');
    }

    passwordReset(request: IPasswordResetRequest ) {
        const obs$ =  this.http.put<IBaseResponse>(WebApiService.serverUrl + this.userUrl + '/PasswordReset', request);
        return this.loading.showUntilCompleted(obs$, 'Resetting password');
    }

    confirmPasswordReset(key: string, newPassword: string ) {
        const obs$ =  this.http.put<IBaseResponse>(WebApiService.serverUrl + this.userUrl + '/ConfirmResetPassword', { ResetKey: key, NewPassword: newPassword});
        return this.loading.showUntilCompleted(obs$, 'Confirming password reset');
    }

    confirmPasswordKey(key: string ) {
        const obs$ =  this.http.put<IBaseResponse>(WebApiService.serverUrl + this.userUrl + '/ConfirmResetKey', { ResetKey: key});
        return this.loading.showUntilCompleted(obs$, 'Confirming key...');
    }

    usernameReminder(request: IUsernameReminderRequest) {
        const obs$ =  this.http.post<IBaseResponse>(WebApiService.serverUrl + this.userUrl + '/RemindUsername', request);
        return this.loading.showUntilCompleted(obs$, 'Requesting reminder');
    }
}
