<div class="card">
    <mat-card class="p-5 mat-elevation-z8">
        <mat-card-title class="title">Invitation Verification
        </mat-card-title>
        <mat-card-content>
            <div *ngIf="organization">
                <p>Invitation acceptance was successful</p>
                <p>Your user name is <b>{{userName}}</b>. Please remeber this to login in the future.</p>
                <div class=" h5">
                    <div class="text-info border-info rounded">
                        <p>{{organization.organizationName}}</p>
                        <p>{{organization.companyRegistration}}</p>
                        <p>{{organization.physicalAddress}}</p>
                        <p>{{organization.postalAddress}}</p>
                    </div>
                    <div class="h6">
                        <p>You have joined the above organization and need to now set your password. </p>
                    </div>
                </div>
                <button mat-raised-button
                        color="primary"
                        (click)="reset()">
                    Set Password
                </button>
            </div>
            <div *ngIf="message">
                <h4 >Invitation acceptance Failed</h4>
                <div class="text-danger">{{message}}</div>
                <p>Please let your system administrator know about the error that you see.</p>
            </div>
        </mat-card-content>
    </mat-card>
</div>