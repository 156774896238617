import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { concatMap, finalize, tap } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class LoadingService implements OnDestroy
{
    name = 'Loading Service';
    private lastCaller: string = '';
    callers: string[] = [];
    // private loading = false;
    private loadingSubject = new BehaviorSubject<string>('');

    loading$: Observable<string> = this.loadingSubject.asObservable();
    private destroyed$ = new Subject<void>();

    constructor()
    {
    }

    showUntilCompleted<T>(obs$: Observable<T>, caller: string): Observable<T>
    {
        // this._caller = caller;

        return of(null)
            .pipe(
                tap(() =>
                {
                    this.callers.push(caller);
                    // this.loadingOn();
                    this.showLoading(caller);
                }),
                concatMap(() => obs$),
                finalize(() =>
                {
                    const index = this.callers.findIndex(c => c == caller);
                    if (index > -1)
                    {
                       this.callers.splice(index, 1); 
                    }
                    // this.callers.shift();
                    // this.logger.log(this.name,'finalized', caller, JSON.stringify(this.callers));
                    // this.loadingOff();
                    this.showLoading(caller);
                })
            );
    }

    private showLoading(caller: string)
    {
        let first: string = this.callers.length > 0 ? this.callers[0] = this.callers[0] : '';
        if (this.lastCaller !== first)
        {
            this.lastCaller = first;    
            // this.logger.log(this.name,'Showing loader', caller, JSON.stringify(this.callers));
            this.loadingSubject.next(first);
        }
    }

    // private loadingOn()
    // {
    //     if (this.loading) {
    //         return;
    //     }

    //     this.loading = true;
    //     this.loadingSubject.next(this._caller);
    // }

    // private loadingOff()
    // {
    //     // console.log('Loading off "' + this._caller + '"');
    //     this.loading = false;
    //     this.loadingSubject.next(null);
    //     this._caller = '';
        
    // }

    ngOnDestroy(): void
    {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

}