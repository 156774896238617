import { CdkDragEnd, CdkDragMove } from '@angular/cdk/drag-drop';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from "@angular/common";
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { animate, state, style, transition, trigger } from '@angular/animations';
import { RouterModule } from '@angular/router';

@Component({
    selector: 'app-side-menu',
    templateUrl: 'side-menu.component.html',
    styleUrls: ['side-menu.component.scss'],
    standalone: true,
    imports: [CommonModule, RouterModule, BrowserAnimationsModule, DragDropModule],
    animations: [
        trigger('openClosed', [
          state('open', style({ width: '{{leftWidth}}px' }), {params: {leftWidth: 0}}),
          state('closed', style({ width: '0px' })),
          transition('* => open', animate('500ms ease-out')),
          transition('* => closed', animate('500ms ease-out'))
        ]),
        trigger('growShrink', [
            state('grow', style({ left: '{{leftWidth}}px' }), {params: {leftWidth: 0}}),
            state('shrink', style({ left: '0px' })),
            transition('* => shrink', animate('500ms ease-out')),
            transition('* => grow', animate('500ms ease-out')),
          ]),
        ],
})
export class SideMenuComponent implements AfterViewInit
{
    @Input() captionHeight = 50;
    @Input() isOpen = false;

    @ViewChild('leftside') leftSide: ElementRef;
    @ViewChild('center') center: ElementRef;

    width = 170;
    savedWidth = 0;

    dragEnded(event: CdkDragEnd)
    {
        console.log('Left width was ' + this.width, event);
        this.width = event.dropPoint.x;
        // this.center.nativeElement.style.left = `${this.width-event.distance.x}px`;
    }

    moved(event: CdkDragMove)
    {
        console.log('moved ', event);
        this.leftSide.nativeElement.style.width = `${event.pointerPosition.x}px`;
    }

    ngAfterViewInit(): void
    {
    }
}