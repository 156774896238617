import { CommonModule } from '@angular/common';
import { IMessages } from '../interfaces/shared.interfaces';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppMaterialModule } from 'src/app/app-material.module';

@Component( {
    selector: 'app-msgs-modal',
    templateUrl: 'messagesModal.component.html',
    styleUrls: ['messagesModal.component.scss'],
    standalone: true,
    imports: [CommonModule, AppMaterialModule]
})

export class MessagesModalComponent
{
    errorInfo: IMessages;
    constructor(@Inject(MAT_DIALOG_DATA) public data: IMessages, public dialogRef: MatDialogRef<MessagesModalComponent>) 
    {
        this.errorInfo = data;
    }

     ok()
     {
         this.dialogRef.close('ok');
     }

     cancel()
     {
        this.dialogRef.close('cancel');
     }
}
