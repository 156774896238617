 <mat-card class="card mat-elevation-z8 p-5">
    <mat-card-title class="title">User Profile for {{user.userName}}</mat-card-title>
    <mat-card-content>

        <form [formGroup]="profileForm"
            ng-submit
            class="mt-4 flex-column justify-start items-stretch gap-12">
            <div class="flex-row justify-start items-center gap-5">
                <div class="flex-50">
                    <mat-form-field class="full-width dense-3">
                        <mat-label>First Name</mat-label>
                        <input matInput
                            type="text"
                            placeholder="Enter your first name"
                            formControlName="firstName">
                        <mat-error *ngIf="firstName.hasError('required')">
                            First Name is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="flex-50">
                    <mat-form-field class="full-width  dense-3">
                        <mat-label>Last Name</mat-label>
                        <input matInput
                            type="text"
                            placeholder="Enter your last name"
                            formControlName="lastName">
                        <mat-error *ngIf="lastName.hasError('required')">
                            Last Name is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="flex-row justify-start items-center gap-5">
                <div class="flex-50">
                    <mat-form-field class="full-width dense-3">
                        <mat-label>Mobile Number</mat-label>
                        <input matInput
                            type="text"
                            placeholder="Enter your mobile number"
                            formControlName="mobileNumber">
                        <mat-error *ngIf="lastName.hasError('minLength') || lastName.hasError('maxLength')">
                            Mobile number must be 10 digits
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="flex-50">
                    <mat-form-field class="full-width dense-3">
                        <mat-label>Email address</mat-label>
                        <input matInput
                            type="email"
                            placeholder="Enter your email address"
                            formControlName="email">
                        <mat-error *ngIf="email.hasError('email')">
                            Email format seems invalid
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </form>

    </mat-card-content>
    <mat-card-actions class="flex-row gap-5">
        <button mat-raised-button
            (click)="save()"
            class="ml-1">
            <mat-icon color="primary" class="mr-1 app-button">save</mat-icon>Save
        </button>
        <button mat-raised-button
            (click)="cancel()"
            class="ml-1">
            <mat-icon color="accent" class="mr-1 app-button">cancel</mat-icon>Cancel
        </button>
    </mat-card-actions>
</mat-card>
